import { requestedClarificationsHeaders } from './components/headers/requestedClarificationsHeaders';
import { UnexcusedAbsences } from './components/UnexcusedAbsences';

export const clarificationsQueryId = 'admin/requestedClarifications';

export const RequestedClarificationsTab = () => {
  return (
    <UnexcusedAbsences
      query={{
        $modify: { requestedClarification: true },
      }}
      headers={requestedClarificationsHeaders}
      queryId={clarificationsQueryId}
      dateColumnGetter={(application) => application?.lastExcuse?.reviewed_at}
    />
  );
};
