import { CustomOption } from '@tymbe/components/inputs/CustomOption';
import classNames from 'classnames';
import { textStyles } from '../style/text-styles';
import { ComponentProps } from 'react';

export const TyCustomOption = ({
  className,
  ...props
}: ComponentProps<typeof CustomOption>) => (
  <CustomOption
    className={classNames(
      className,
      'py-1.5 px-2 min-w-full',
      textStyles.pBody2Rg,
      'text-base-front-1'
    )}
    {...props}
  />
);
