import { ComponentProps } from 'react';
import { Link, To } from 'react-router-dom';
import { TyCard } from './Card';

type LinkCardProps = Omit<
  ComponentProps<typeof TyCard> & {
    to: To;
    target?: React.HTMLAttributeAnchorTarget;
    disabled?: boolean;
  },
  'onClick'
>;

export const TyLinkCard = ({
  to,
  target,
  className,
  ...props
}: LinkCardProps) => {
  if (props.disabled) return <TyCard className={className} {...props} />;

  return (
    <Link to={to} target={target}>
      <TyCard className={className} {...props} />
    </Link>
  );
};
