import { Paginated, Query } from '@feathersjs/feathers';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import ShiftInfo from './ShiftInfo';
import feathersClient from '../../../apiClient';
import Card from '../../../components/Layout/Card';
import { TablePagination } from '../../../components/Table';
import defaultPageSizeOptions from '../../../components/Table/table.utils';
// Advance filter -->
import TyInteractiveLabels from '../../../components/TyAdvancedSearch/Components/TyInteractiveLabels';
import apiBuilder from '../../../components/TyAdvancedSearch/Libs/apiBuilder';
import { labelsValues } from '../../../components/TyAdvancedSearch/Libs/enums';
import TyAdvancedSearchUserShift from '../../../components/TyAdvancedSearch/UserShift';
import apiTransformationsUserShift from '../../../components/TyAdvancedSearch/UserShift/apiTransformationsUserShift';
// Advance filter <--
import useURLParamsHandler, { TyAdvancedSearchObject } from '../../../hooks/UrlParamsHandler/useURLParamsHandler';
import { ApplicationData } from '../../../types/TymbeApi';
import Spinner from '../../../utils/spinner';

const ShiftsContainer = () => {
  const { id: personId } = useParams();
  const queryClient = useQueryClient();
  const [{ tyAdvanceSearch }, setUrlParamsHandler] = useURLParamsHandler();

  const [paginationStart, setPaginationStart] = useState<number>(0);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(defaultPageSizeOptions[0].value);

  const [advanceSearch, setAdvanceSearch] = useState<TyAdvancedSearchObject>(
    Object.keys(tyAdvanceSearch).length ? tyAdvanceSearch : { showSystemCancelled: false },
  );

  const eagerQuery = '[shift(withDeleted).[company(withDeleted), branchoffice(withDeleted).parent], employer,'
    + 'attendance.[creditTransaction, confirmedBy(withDeleted), personLiability, surveyAnswer], excuses, lastExcuse]';

  const [query, setQuery] = useState<Query>({
    'application.person_id': personId,
    $eager: eagerQuery,
    $sort: { 'shift.start_time': -1 },
    $skip: paginationStart,
    $limit: paginationPageSize,
    $joinRelation: 'shift(withDeleted)',
  });

  const { isLoading, data: personApplication } = useQuery(['applicationList', query], async () => {
    const result = await feathersClient.service('application').find({
      query,
    }) as Paginated<ApplicationData>;
    if (result?.data?.length) {
      result.data.forEach((application) =>
        queryClient.setQueryData(['application', application.id], () => application), { staleTime: Infinity });
    }
    return result;
  }, { enabled: !!personId, keepPreviousData: true });

  const onPageChange = useCallback((pageStart: number, pageEnd: number) => {
    setPaginationStart(pageStart);
    setPaginationPageSize(pageEnd - pageStart);
  }, []);

  useEffect(() => {
    let q = {
      'application.person_id': personId,
      $eager: eagerQuery,
      $sort: { 'shift.start_time': -1 },
      $skip: paginationStart,
      $limit: paginationPageSize,
      $joinRelation: 'shift(withDeleted)',
    };
    if (advanceSearch) {
      setUrlParamsHandler(advanceSearch);
      q = {
        ...q,
        ...apiBuilder(
          advanceSearch,
          apiTransformationsUserShift,
        ),
      };
    }
    setQuery(q);
  }, [advanceSearch, paginationPageSize, paginationStart, personId]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Card>
      <div className="px-2.5 text-center space-y-5  ">
        <div className="flex justify-end">
          <TyAdvancedSearchUserShift
            pageTitle="Směny"
            setInitialValues={setAdvanceSearch}
            initialValues={advanceSearch}
          />
        </div>
        <div>
          <TyInteractiveLabels
            labelsValues={labelsValues}
            setInitialValues={setAdvanceSearch}
            initialValues={advanceSearch}
          />
        </div>
      </div>
      <div>
        {personApplication?.data?.map((application: ApplicationData) => (
          <div key={application.id} className="pb-3">
            <ShiftInfo applicationId={application.id} />
          </div>
        ))}
      </div>
      <TablePagination
        rowsCount={personApplication?.total}
        onChangePage={onPageChange}
      />
    </Card>
  );
};

export default ShiftsContainer;
