import classNames from 'classnames';
import { textStyles } from '../style/text-styles';

export const Label = ({
  className = '',
  ...props
}: React.ComponentPropsWithoutRef<'label'>) => {
  return (
    <label className={classNames(textStyles.cLabel, className)} {...props} />
  );
};

export const HelperText = ({
  className = '',
  ...props
}: React.ComponentPropsWithoutRef<'span'>) => {
  return (
    <span className={classNames(textStyles.cHelperText, className)} {...props} />
  );
};
