import { SvgProps } from '@tymbe/components/icons';

export const CrossIcon = ({ className = '', ...props }: SvgProps) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    className={`${className} h-[20px] w-[20px]`}
    {...props}
  >
    <path
      d="M15.8327 4.16675L4.16602 15.8334"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16536 4.16675L15.832 15.8334"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
