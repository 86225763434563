import { TextArea } from '@tymbe/components/inputs';
import classNames from 'classnames';
import { useFieldState } from 'informed';
import { ComponentProps } from 'react';
import { textStyles } from '../style/text-styles';
import { Label } from '../text/components';
import { TyInputError } from './InputError';

export const TyTextArea = ({
  className = '',
  ...props
}: ComponentProps<typeof TextArea>) => {
  const { focused, error, showError } = useFieldState<string>(props.name);
  const displayError = !!(showError && error);

  return (
    <div>
      {props.label && (
        <Label
          className={classNames(displayError && 'text-system-front-error-text')}
        >
          {props.label}
        </Label>
      )}
      <div
        className={classNames(
          className,
          'bg-base-back-inverse',
          'border border-special-outline-default',
          'rounded-lg',
          'px-1.5 py-1',
          'w-full',
          textStyles.pBody2Md,
          focused && 'border-special-outline-selected',
          displayError && 'border-special-outline-error'
        )}
      >
        <TextArea
          className={classNames(
            'placeholder:font-geistRg',
            'placeholder:text-base-front-3',
            'placeholder:font-normal'
          )}
          {...props}
        />
      </div>
      <TyInputError name={props.name} />
    </div>
  );
};
