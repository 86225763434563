import { ApplicationExcuseData } from '@tymbe/schema/src/application-excuse.interface';
import { ApplicationExcuse } from './ApplicationExcuse';
import { ShowAllButton } from '@tymbe/ty-components/button';
import { textStyles } from '@tymbe/ty-components/style/text-styles';

type Props = {
  excuses: ApplicationExcuseData[];
  titleClassName?: string;
  disabledOnTap?: boolean;
  activeExcuseId?: string;
};

export const ApplicationExcuses = ({
  excuses,
  titleClassName = textStyles.pBody2Rg,
  disabledOnTap = false,
  activeExcuseId,
}: Props) => {
  const excusesElements = excuses
    .sort((a, b) => b.created_at.localeCompare(a.created_at))
    .map((excuse) => (
      <ApplicationExcuse
        excuse={excuse}
        key={excuse.id}
        selected={activeExcuseId === excuse.id.toString()}
        disabledOnTap={disabledOnTap}
      />
    ));

  return excuses.length === 0 ? null : (
    <div>
      <span className={titleClassName}>Omluvenky</span>
      <ShowAllButton children={excusesElements} />
    </div>
  );
};
