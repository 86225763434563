import { PersonData } from '@tymbe/schema/person.interface';
import { Gender } from '@tymbe/schema/enums';
import classNames from 'classnames';

type Props = {
  person?: PersonData;
  className?: string;
};

export const UserAvatar = ({ person, className = '' }: Props) => {
  return (
    <div
      className={classNames(
        person?.personData?.gender === Gender.MALE
          ? 'bg-special-back-man'
          : 'bg-special-back-woman',
        'bg-special-back-selected-2',
        'text-base-front-1',
        'w-9 h-9 rounded-full',
        'flex items-center justify-center',
        className
      )}
    >
      {person?.first_name.charAt(0)}
      {person?.last_name.charAt(0)}
    </div>
  );
};
