import { EditDocumentAction } from './enums';
import { DocumentTypeData } from '../types/TymbeApi';
import { DocumentType } from '@tymbe/schema/enums';

// eslint-disable-next-line import/prefer-default-export
export const getDocumentAction = (
  document: DocumentTypeData
): EditDocumentAction => {
  if (document.template_id && !document.json) {
    return EditDocumentAction.GOOGLE;
  }
  if (!document.template_id) {
    return EditDocumentAction.EDITOR_JS;
  }
  return EditDocumentAction.UNKNOWN;
};

export const getDocumentTypeTranslation = (document?: DocumentType): string => {
  switch (document) {
    case DocumentType.CONTRACT_DPC:
      return 'DPČ';
    case DocumentType.CONTRACT_DPP:
      return 'DPP';
    case DocumentType.CONTRACT_HPP:
      return 'HPP';
    default:
      return 'Jiné';
  }
};
