import classNames from 'classnames';
import { ComponentProps } from 'react';
import { H3 } from '../text';

export const TyTableTitle = ({
  className = '',
  ...props
}: ComponentProps<typeof H3>) => (
  <H3
    className={classNames(
      className,
      'py-9',
      'px-10',
      'bg-base-front-inverse',
      'rounded-t-3xl'
    )}
    {...props}
  />
);
