import { ComponentPropsWithoutRef } from 'react';

export const TyReplayIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.97333 3.76174V1.90174C7.97333 1.60174 7.61333 1.45507 7.40666 1.66841L4.87333 4.19507C4.73999 4.32841 4.73999 4.53507 4.87333 4.66841L7.39999 7.19507C7.61333 7.40174 7.97333 7.25507 7.97333 6.95507V5.09507C10.46 5.09507 12.4267 7.37507 11.88 9.95507C11.5667 11.4684 10.34 12.6884 8.83333 13.0017C6.45333 13.5017 4.33333 11.8684 4.01333 9.66174C3.96666 9.34174 3.68666 9.09507 3.35999 9.09507C2.95999 9.09507 2.63999 9.44841 2.69333 9.84841C3.10666 12.7751 5.89333 14.9417 9.04666 14.3284C11.1267 13.9217 12.8 12.2484 13.2067 10.1684C13.8667 6.74841 11.2667 3.76174 7.97333 3.76174Z" />
  </svg>
);
