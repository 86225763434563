import convertToArray from '../../../../../utils/convertToArray';

interface DefaultSettingsCheckProps<T, P> {
  actualSettings: T[] | T;
  defaultSettings: P[] | P;
  onDisplayValue: (value: NonNullable<P>) => string;
  onCompare: (defaultSetting: P, actualSetting: T) => boolean;
}

const DefaultSettingsCheck = <T, P> (
  { defaultSettings, actualSettings, onDisplayValue, onCompare }: DefaultSettingsCheckProps<T, P>) => {
  const deletedSetts = convertToArray(defaultSettings).flatMap((f) => (f ? [f] : [])).filter((setting) => {
    const contains = convertToArray(actualSettings).some((actualSetting) => onCompare(setting, actualSetting));
    return !contains && setting;
  });

  if (!deletedSetts.length || !defaultSettings) return null;

  const getMessage = () => {
    const params = deletedSetts.map((set) => onDisplayValue(set)).join(', ');
    return deletedSetts.length === 1 ? `Z pole byl odebrán výchozí parametr: ${params}` : `Z pole byly odebrány výchozí parametry: ${params}`;
  };

  return (
    <div className="text-xs text-danger p-1">
      {getMessage()}
    </div>
  );
};

export default DefaultSettingsCheck;
