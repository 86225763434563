import { ComponentProps, useContext } from 'react';
import { Radio } from '@tymbe/components/inputs/radio';
import { Label } from '../../text/components';
import { textStyles } from '../../style/text-styles';
import RadioGroupContext from '@tymbe/components/inputs/radio/RadioGroupContext';
import classNames from 'classnames';

type TyRadioProps = { labelClassName?: string } & ComponentProps<typeof Radio>;

export const TyRadio = ({ className = '', ...props }: TyRadioProps) => {
  const radioGroupContext = useContext(RadioGroupContext);
  if (!radioGroupContext) return null;

  const { id } = radioGroupContext;

  return (
    <Label
      htmlFor={`${id}-${props.value}`}
      className={classNames(className, textStyles.pCaptionMd, 'cursor-pointer')}
    >
      <Radio {...props} />
      {props.label}
    </Label>
  );
};
