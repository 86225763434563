import React from 'react';
import { isImage } from '.';
import { IFrameViewer } from './IFrameViewer';
import { ImageViewer } from './ImageViewer';

export type FileViewerProps = {
  blob?: Blob;
  className?: string;
  spinner: React.ReactNode;
  fileErrorIndicator: React.ReactNode;
  iFrameClassName?: string;
  imgClassName?: string;
  isError?: boolean;
  isLoading?: boolean;
};

const getImageUrl = (imageBlob: Blob) => URL.createObjectURL(imageBlob);

/**
 * Component that displays given file.
 * In case of image shows it as an <img />
 * In case of other files shows an <iframe />
 * Handles errors and loading states by displaying provided
 * spinner and fileErrorIndicator
 */
export const BlobFileViewer = ({
  blob,
  className = '',
  iFrameClassName = '',
  imgClassName = '',
  spinner,
  fileErrorIndicator,
  isError = false,
  isLoading = false,
}: FileViewerProps) => {
  if (isLoading) return <div className={className}>{spinner}</div>;
  if (isError || !blob) return <div className={className}>{fileErrorIndicator}</div>;

  const showIframe = blob && !isImage(blob);

  return (
    <div className={className}>
      {blob &&
        (showIframe ? (
          <IFrameViewer
            src={getImageUrl(blob!)}
            fileErrorIndicator={fileErrorIndicator}
            spinner={spinner}
            className={iFrameClassName}
          />
        ) : (
          <ImageViewer
            src={getImageUrl(blob!)}
            className={imgClassName}
            fileErrorIndicator={fileErrorIndicator}
            spinner={spinner}
          />
        ))}
    </div>
  );
};
