import { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FieldState } from 'informed';

export type OptionProps = {
  value: number;
  label: string;
};

type usePaginationElementsProps = {
  onPageChange: (page: number) => void;
  defaultPageSize: number;
  rowsCount?: number;
};

export const usePaginationElements = ({
  onPageChange,
  rowsCount = 0,
  defaultPageSize,
}: usePaginationElementsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageSize = searchParams.get('pageSize')
    ? Number(searchParams.get('pageSize'))
    : defaultPageSize;

  const visiblePage = searchParams.get('page')
    ? Number(searchParams.get('page'))
    : 1;

  const pagesCount = Math.ceil(rowsCount / pageSize);

  const setVisiblePage = (page: number) => {
    setSearchParams((prev) => {
      prev.set('page', page.toString());
      return prev;
    });
  };

  const setPageSize = (size: number) => {
    setSearchParams((prev) => {
      prev.set('pageSize', size.toString());
      return prev;
    });
  };

  // Previous page button
  const onPrevPageButtonClick = () => {
    if (visiblePage > 1) {
      onPageChange(visiblePage - 1);
    }
  };
  const isPrevPageButtonDisabled = visiblePage <= 1;

  // Next page button
  const onNextPageButtonClick = () => {
    if (visiblePage < pagesCount) {
      onPageChange(visiblePage + 1);
    }
  };
  const isNextPageButtonDisabled = visiblePage === pagesCount;

  // Left spread button
  const onLeftSpreadButtonClick = () =>
    onPageChange(Math.max(visiblePage - 5, 1));
  const showLeftSpreadButton = pagesCount > 7 && visiblePage > 4;

  // Right spread button
  const onRightSpreadButtonClick = () =>
    onPageChange(Math.max(visiblePage + 5, 1));
  const showRightSpreadButton = pagesCount > 7 && visiblePage < pagesCount - 3;

  // Number buttons
  const generateNumberedPageButtons = (
    renderButton: (i: number) => ReactNode
  ): ReactNode[] => {
    let buttonsStart = visiblePage - 2;
    let buttonsEnd = Math.min(pagesCount - 1, visiblePage + 2);
    if (visiblePage < 5) {
      buttonsStart = 2;
      buttonsEnd = Math.min(5, pagesCount - 1);
    } else if (visiblePage > pagesCount - 3) {
      buttonsStart = Math.max(2, pagesCount - 5);
      buttonsEnd = pagesCount - 1;
    }

    const buttons: ReactNode[] = [];

    for (let i = buttonsStart; i <= buttonsEnd; i += 1) {
      buttons.push(renderButton(i));
    }

    return buttons;
  };

  const showLastPageButton = pagesCount > 1;

  const onPageSizeChange = (newValue: FieldState<OptionProps>, event: any) => {
    const newPageSize = newValue.value.value;

    // Also update the page to keep the records on the page
    const pageStart = (visiblePage - 1) * pageSize;
    const newPage = Math.floor(pageStart / newPageSize) + 1;
    setPageSize(newPageSize);
    onPageChange(newPage);
  };

  return {
    visiblePage,
    pagesCount,
    onPrevPageButtonClick,
    isPrevPageButtonDisabled,
    onNextPageButtonClick,
    isNextPageButtonDisabled,
    setVisiblePage,
    onLeftSpreadButtonClick,
    showLeftSpreadButton,
    onRightSpreadButtonClick,
    showRightSpreadButton,
    generateNumberedPageButtons,
    showLastPageButton,
    onPageSizeChange,
    pageSize,
  };
};
