import React from 'react';
import { FieldApi, FieldProps, FieldState, RadioGroupProps } from 'informed';

export type TyRadioGroupContextModel = FieldProps<
  RadioGroupProps,
  string | undefined,
  Record<string, unknown>
> & {
  radioGroupApi: FieldApi<string | undefined>;
  radioGroupState: FieldState<string | undefined>;
};

const TyRadioGroupContext = React.createContext<
  TyRadioGroupContextModel | undefined
>(undefined);

export default TyRadioGroupContext;
