import classNames from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { textStyles } from '../style/text-styles';

export const TyNavLink = ({
  className,
  children,
  ...props
}: NavLinkProps) => {
  return (
    <NavLink
      className={({ isActive }) =>
        classNames(
          textStyles.pBody2Sb,
          isActive ? 'text-base-front-active active' : 'text-base-front-2',
          'hover:text-base-front-1',
          'hover:bg-base-back-inverse',
          'rounded-lg',
          'px-3',
          'py-2',
          'transition',
          'duration-400',
          'hover:shadow-s',
          'flex items-center gap-2',
          className
        )
      }
      {...props}
    >
      {children}
    </NavLink>
  );
};
