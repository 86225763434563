import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type useSortProps = {
  field?: string;
  initSort?: Record<string, number>;
  onSort?: (field: string, activeSortOrder: number) => void;
};

const parseSort = (sort?: string | null): { [key: string]: number } => {
  if (!sort) return {};
  try {
    return JSON.parse(sort);
  } catch (e) {
    return {};
  }
};

export const useSort = ({ field, initSort, onSort }: useSortProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = parseSort(searchParams.get('sort')) ?? initSort;
  const [sortOrder, setSortOrder] = useState(field ? sort?.[field] ?? 0 : 0);

  const changeSort = () => {
    if (!field) return;

    let newSort = 0;
    if (sortOrder === 0) {
      newSort = -1;
    } else if (sortOrder === -1) {
      newSort = 1;
    }

    setSortOrder(newSort);
    onSort?.(field, newSort);
    setSearchParams((prev) => {
      const parsedSort = parseSort(prev.get('sort'));

      if (newSort === 0) {
        delete parsedSort[field];
      } else {
        parsedSort[field] = newSort;
      }

      if (Object.keys(parsedSort).length > 0) {
        prev.set('sort', JSON.stringify(parsedSort));
      } else {
        prev.delete('sort');
      }

      return prev;
    });
  };

  return {
    sortOrder,
    changeSort,
  };
};
