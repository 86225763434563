import classNames from 'classnames';
import { textStyles } from '../style/text-styles';

type BadgePillVariant = 'default' | 'info' | 'success' | 'warning' | 'error';

interface BadgePillProps {
  variant: BadgePillVariant;
  significant?: boolean;
  children?: React.ReactNode;
}

const variantStyles: Record<BadgePillVariant, string> = {
  default: 'bg-system-back-default text-system-back-default-significant',
  info: 'bg-system-back-info text-system-back-info-significant',
  success: 'bg-system-back-success text-system-back-success-significant',
  warning: 'bg-system-back-warning text-system-back-warning-significant',
  error: 'bg-system-back-error text-system-back-error-significant',
};

const significantVariantStyles: Record<BadgePillVariant, string> = {
  default: 'bg-system-back-default-significant text-base-front-inverse',
  info: 'bg-system-back-info-significant text-base-front-inverse',
  success: 'bg-system-back-success-significant text-base-front-inverse',
  warning: 'bg-system-back-warning-significant text-base-front-inverse',
  error: 'bg-system-back-error-significant text-base-front-inverse',
};

export const BadgePill = ({
  variant,
  significant = false,
  children,
}: BadgePillProps) => {
  const style = significant
    ? significantVariantStyles[variant]
    : variantStyles[variant];

  return (
    <div
      className={classNames(
        textStyles.pCaptionMd,
        `${style} flex rounded-md px-2 py-1 justify-center text-nowrap`
      )}
    >
      {children}
    </div>
  );
};
