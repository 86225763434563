import { PaySupplementRules } from '@tymbe/schema/pay-supplement.interface';
import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';
import { Relevant } from 'informed';

import PaySupplementItem from './PaySupplementItem';
import PaySupplementItemWeekend from './PaySupplementItemWeekend';
import TyCheckbox from '../inputs/TyCheckbox';

interface PaySupplementFormValues {
  $paySupplement: boolean;
}

interface PaySupplementProps {
  isDisabled: boolean;
  name: string;
  initialValues: PaySupplementRules | null;
  defaultSettings?: PaySupplementRules | null;
}

const PaySupplement = ({
  isDisabled,
  name,
  initialValues,
  defaultSettings = undefined,
}: PaySupplementProps) => (
  <div className="grid">
    <TyCheckbox
      name="$paySupplement"
      label="Příplatky"
      disabled={isDisabled}
      defaultValue={Boolean(initialValues)}
    />
    <Relevant<PaySupplementFormValues>
      when={({ formState }) => formState.values.$paySupplement}
    >
      <PaySupplementItem
        type={PaySupplementType.Holiday}
        name={`${name}.Holiday`}
        label="Za svátky"
        initialValues={initialValues?.Holiday}
        isDisabled={isDisabled}
        defaultSettings={defaultSettings}
      />
      <PaySupplementItemWeekend
        name={`${name}`}
        label="Za víkendy"
        initialValues={initialValues}
        isDisabled={isDisabled}
        defaultSettings={defaultSettings}
      />
      <PaySupplementItem
        type={PaySupplementType.Night}
        name={`${name}.Night`}
        label="Za práci v noci"
        initialValues={initialValues?.Night}
        isDisabled={isDisabled}
        defaultSettings={defaultSettings}
      />
    </Relevant>
    <Relevant<PaySupplementFormValues>
      when={({ formState }) => (
        defaultSettings !== undefined && !!defaultSettings && !formState.values.$paySupplement)}
    >
      <div className="text-xs text-danger p-1">
        Ve výchozím nastavení jsou příplatky vyplněny.
      </div>
    </Relevant>

  </div>
);

export default PaySupplement;
