import { AbsenceState } from '@tymbe/schema/enums';

const absenceStyleSettings = {
  [AbsenceState.APPROVED]: {
    textColour: 'text-primary-400',
    text: 'Omluvená absence',
  },
  [AbsenceState.REJECTED]: {
    textColour: 'text-error-600',
    text: 'Neuznaná absence',
  },
  [AbsenceState.PROCESSING]: {
    textColour: 'text-secondary-600',
    text: 'Zpracováváme absenci',
  },
  [AbsenceState.CLARIFICATION_NEEDED]: {
    textColour: 'text-danger-600',
    text: 'Upřesnění absence',
  },
  [AbsenceState.SYSTEM_APPROVED]: {
    textColour: 'text-primary-400',
    text: 'Omluvená absence',
  },
  [AbsenceState.SYSTEM_REJECTED]: {
    textColour: 'text-error-600',
    text: 'Neuznaná absence',
  },
  [AbsenceState.HISTORICAL_UNKNOWN]: null,
};
export default absenceStyleSettings;
