export const processingAbsenceHeaders = [
  {
    value: 'application:person.id',
    label: 'Tymber',
    sortable: false,
  },
  {
    value: 'application:shift.name',
    label: 'Směna',
    sortable: false,
  },
  {
    value: 'application:shift.start_time',
    label: 'Datum směny',
    sortable: true,
  },
  {
    value: 'updated_at',
    label: 'Datum omluvy',
    sortable: true,
  },
  {
    value: 'absence_category',
    label: 'Důvod absence',
    sortable: true,
  },
  {
    value: 'reason',
    label: 'Upřesnění',
    sortable: false,
  },
  {
    value: 'file',
    label: 'Soubor',
    sortable: false,
  },
  {
    value: 'actions',
    label: 'Akce',
    sortable: false,
  },
];

