export const SearchIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className={`${props.className} h-[20px] w-[20px]`}
  >
    <path d="M13.3137 12.0595H12.6553L12.422 11.8345C13.422 10.6679 13.9387 9.07619 13.6553 7.38452C13.2637 5.06786 11.3303 3.21786 8.99701 2.93452C5.47201 2.50119 2.50534 5.46786 2.93867 8.99286C3.22201 11.3262 5.07201 13.2595 7.38867 13.6512C9.08034 13.9345 10.672 13.4179 11.8387 12.4179L12.0637 12.6512V13.3095L15.6053 16.8512C15.947 17.1929 16.5053 17.1929 16.847 16.8512C17.1887 16.5095 17.1887 15.9512 16.847 15.6095L13.3137 12.0595ZM8.31367 12.0595C6.23867 12.0595 4.56367 10.3845 4.56367 8.30952C4.56367 6.23452 6.23867 4.55952 8.31367 4.55952C10.3887 4.55952 12.0637 6.23452 12.0637 8.30952C12.0637 10.3845 10.3887 12.0595 8.31367 12.0595Z" />
  </svg>
);
