import { ApplicationState, MpsvReportResponseStatus, MpsvReportStatus, ShiftType } from '@tymbe/schema/enums';
import { Menu } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import feathersClient from '../../apiClient';
import { useUser } from '../../apiClient/ApiContext';
import {
  CalendarIcon,
  CertificateIcon,
  HardFolderIcon,
  HomeIcon,
  ListIcon,
  LockIcon,
  UniversityHatIcon,
} from '../../components/icons';
import { selectOpenSidebarKeys, setOpenSidebarKeysThunk } from '../../data/store/slices/user-interface';
import { Roles } from '../../utils/enums';
import Tymbe from '../partials/Tymbe';

const { SubMenu } = Menu;
const Sidebar = ({ iconcolor = '#CCD2E3', background = '#28367B' }) => {
  let state = {
    current: window ? window.location.pathname.split('/')[1] : null,
  };
  const host = window ? window.location.host : null;
  const isStage = host === 'admin.tytodas.cz' || host === 'admin2.tytodas.cz';
  const isLocal = host && ['127.0.0.1:5173', '127.0.0.1:5174', 'localhost:5173', 'localhost:5174'].includes(host);
  const user = useUser();
  const isAdmin = user.hasRoles([
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.ADMIN,
    Roles.TYMBE_COORDINATOR,
  ]);

  const history = useNavigate();
  const dispatch = useDispatch();
  const openKeys = useSelector(selectOpenSidebarKeys);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    dispatch(setOpenSidebarKeysThunk(latestOpenKey ? [latestOpenKey] : []));
  };

  const { data: unresolvedPayments } = useQuery(['sidebarUnresolvedPayments'], async () =>
    feathersClient.service('payment-request').find({
      query: { $modify: 'unpaid' },
    }));

  const { data: waitingDocuments } = useQuery(['sidebarWaitingDocuments'], async () =>
    feathersClient.service('person-document').find({
      query: { approved: { $null: true } },
    }));

  const now = moment().tz('Europe/Prague');
  const { data: mpsvReportErrors } = useQuery(['mpsvReportErrors'], async () =>
    feathersClient.service('application').find({
      query: {
        $limit: 0,
        $eager: '[person.personData, employer, mpsvReport, shift.[branchoffice.parent]]',
        $leftJoinRelation: '[person.personData, mpsvReport, shift.branchoffice]',
        'person:personData.nationality': { $nin: ['CZE'] },
        'shift.start_time': { $gte: moment('2024-07-01').startOf('day').toISOString() },
        'shift.type': ShiftType.REGULAR,
        employer_id: { $in: [106, 113, 116, 125] },
        state: ApplicationState.CONFIRMED,
        $or: [{
          'mpsvReport.status': { $in: [MpsvReportStatus.ERROR] },
        }, {
          'mpsvReport.response_status': { $in: [MpsvReportResponseStatus.ERROR, MpsvReportResponseStatus.FAILURE] },
        }, {
          'shift.start_time': {
            $gte: now.startOf('day').toISOString(),
            $lte: now.endOf('day').toISOString(),
          },
          mpsvReport: { $null: true },
        }],
      },
    }));

  const menuStyle = {
    background,
    color: iconcolor,
  };
  const handleClick = (e) => {
    state = { current: e.key };
    history(`/${e.key}`);
  };

  const loggedUserCompanyId = user?.person?.company[0]?.id;

  return (
    <div style={menuStyle} className="ty-sidebar-container">
      <div style={{ marginBottom: '28px', display: 'flex', justifyContent: 'center' }}>
        <Tymbe />
      </div>
      {isLocal && (
        <div className="h-14">
          <div className="w-full bg-primary absolute left-0 right-0 top-20">
            <h3 className="text-center">LOCALHOST 🤖</h3>
          </div>
        </div>
      )}
      {isStage && (
        <div className="h-14">
          <div className="w-full bg-danger absolute left-0 right-0 top-20">
            <h3 className="text-center" style={{ color: 'white' }}>TESTOVACÍ VERZE</h3>
          </div>
        </div>
      )}
      <Menu
        onClick={handleClick}
        selectedKeys={[state.current]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        mode="inline"
        className="ty-sidebar"
      >
        {/* <Menu.Item
          key="dashboard"
          icon={<DashboardIcon iconcolor={iconcolor} />}
          className="ty-icon-sidebar"
          hidden={!isAdmin}
        >
          Nástěnka
        </Menu.Item> */}
        {isAdmin && (
          <Menu.Item
            icon={<CalendarIcon iconcolor={iconcolor} />}
            key="calendar"
            className="ty-icon-sidebar"
          >
            <div className="flex gap-2 align-center">
              Kalendář
              <svg
                fill="currentColor"
                viewBox="0 0 36 36"
                version="1.1"
                style={{ width: '40px', height: '40px' }}
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                <g id="SVGRepo_iconCarrier"><title>beta-line</title>
                  <path
                    d="M7.21,14.07h3a1.61,1.61,0,0,1,1.81,1.5,1.44,1.44,0,0,1-.84,1.34,1.67,1.67,0,0,1,1.1,1.53,1.75,1.75,0,0,1-2,1.63H7.21Zm2.71,2.42c.48,0,.82-.28.82-.67s-.34-.65-.82-.65H8.49v1.32Zm.2,2.48a.75.75,0,1,0,0-1.47H8.49V19Z"
                    className="clr-i-outline clr-i-outline-path-1"
                  />
                  <path
                    d="M14.55,15.23v1.2h3v1.16h-3v1.32h3.33v1.16H13.26v-6h4.62v1.16Z"
                    className="clr-i-outline clr-i-outline-path-2"
                  />
                  <path
                    d="M20.41,15.23H18.54V14.07h5v1.16H21.7v4.84H20.41Z"
                    className="clr-i-outline clr-i-outline-path-3"
                  />
                  <path
                    d="M28,19.12H25.32l-.38.95H23.5l2.44-6h1.44l2.45,6H28.38ZM27.55,18l-.89-2.19L25.77,18Z"
                    className="clr-i-outline clr-i-outline-path-4"
                  />
                  <path
                    d="M8.06,30a.84.84,0,0,1-.38-.08A1,1,0,0,1,7.06,29V25h-4a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1h30a1,1,0,0,1,1,1V24a1,1,0,0,1-1,1H13.48L8.77,29.71A1,1,0,0,1,8.06,30Zm-4-7h4a1,1,0,0,1,1,1v2.59l3.3-3.3a1,1,0,0,1,.7-.29h19V11h-28Z"
                    className="clr-i-outline clr-i-outline-path-5"
                  />
                  <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
                </g>
              </svg>
            </div>
          </Menu.Item>
        )}
        <Menu.Item
          icon={<HomeIcon iconcolor={iconcolor} />}
          key="shift"
          className="ty-icon-sidebar"
        >
          Objednávky
        </Menu.Item>
        <Menu.Item
          icon={<HardFolderIcon iconcolor={iconcolor} />}
          key="attendance"
          className="ty-icon-sidebar"
        >
          Docházka
        </Menu.Item>
        <Menu.Item
          icon={<UniversityHatIcon iconcolor={iconcolor} />}
          key="tymber"
          className="ty-icon-sidebar"
        >
          Tymbeři
        </Menu.Item>
        {isAdmin
          && (
            <Menu.Item
              icon={<CertificateIcon iconcolor={iconcolor} />}
              key="company"
              className="ty-icon-sidebar"
            >
              Firma
            </Menu.Item>
          )}
        <Menu.Item
          icon={isAdmin ? <UniversityHatIcon iconcolor={iconcolor} /> : <LockIcon iconcolor={iconcolor} />}
          className="ty-icon-sidebar"
          key="blocks"
          hidden={!user.hasRoles([
            Roles.SUPER_ADMIN,
            Roles.TYMBE_ADMIN,
            Roles.ADMIN,
            Roles.TYMBE_COORDINATOR,
            Roles.COMPANY,
            Roles.BRANCHOFFICE_MANAGER,
          ])}
        >
          Blokace
        </Menu.Item>
        <Menu.Item
          icon={<ListIcon iconcolor={iconcolor} />}
          key="education"
          disabled
          hidden
          className="ty-icon-sidebar"
        >
          Seznam školení
        </Menu.Item>
        {isAdmin ? (
          <>
            <Menu.Item
              key="monthly-wage"
              className="ty-icon-sidebar"
            >
              Měsíční DPP
            </Menu.Item>
            <Menu.Item
              key="admin-shift"
              className="ty-icon-sidebar"
            >
              Směny
            </Menu.Item>
            <SubMenu
              hidden={!user.hasRoles([Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN])}
              key="admin-administration"
              title="Administrace"
            >
              <Menu.Item className="ty-icon-sidebar" key="administration/shortages">
                Manka
              </Menu.Item>
              <Menu.Item
                className="ty-icon-sidebar"
                key="administration/payment"
              >
                Výplaty (
                {unresolvedPayments?.total || 0}
                )
              </Menu.Item>
              <Menu.Item
                className="ty-icon-sidebar"
                key="administration/position"
              >
                Pozice
              </Menu.Item>
              <Menu.Item
                key="administration/foreclosures"
                className="ty-icon-sidebar"
              >
                Exekuce
              </Menu.Item>
              <Menu.Item
                key="admin-administration-documents"
                className="ty-icon-sidebar"
              >
                Dokumenty (
                {waitingDocuments?.total || 0}
                )
              </Menu.Item>
              <Menu.Item
                key="administration/utility"
                className="ty-icon-sidebar"
              >
                Pomůcky
              </Menu.Item>
              <Menu.Item
                key="administration/email"
                className="ty-icon-sidebar"
              >
                Emaily
              </Menu.Item>
              <Menu.Item
                key="administration/reporting"
                className="ty-icon-sidebar"
              >
                Reporting
              </Menu.Item>
              <Menu.Item
                key="administration/mpsv"
                className="ty-icon-sidebar"
              >
                MPSV ({mpsvReportErrors?.total || 0})
              </Menu.Item>
              <Menu.Item
                key="administration/internal-users"
                className="ty-icon-sidebar"
              >
                Interní uživatelé
              </Menu.Item>
              <Menu.Item
                className="ty-icon-sidebar"
                key="administration/absence"
              >
                Absence
              </Menu.Item>
            </SubMenu>
          </>
        ) : (
          <Menu.Item
            icon={<CertificateIcon iconcolor={iconcolor} />}
            key={`company/${loggedUserCompanyId}`}
            className="ty-icon-sidebar"
          >
            Firma
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};
Sidebar.propTypes = {
  background: PropTypes.string,
  iconcolor: PropTypes.string,
};

export default Sidebar;
