export const TySortIconUp = () => (
  <svg
    width="6"
    height="14"
    viewBox="0 0 6 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 0L5.59808 4.5H0.401924L3 0Z" fill="currentColor" />
    <path d="M3 14L0.401925 9.5L5.59808 9.5L3 14Z" fill="#BAC0D9" />
  </svg>
);

