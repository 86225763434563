import { FileType } from '@tymbe/components/files';
import { IconButton } from '../button';
import { PdfIcon, ImagePlaceholderIcon } from '../icons';
import { TyTooltip } from '../text/tooltip';

type FileIconProps = {
  type: FileType;
  onClick?: () => void;
  fileName?: string;
};

export const FileIcon = ({ type, onClick, fileName }: FileIconProps) => {
  return (
    <TyTooltip overlay={fileName} placement="top">
      <IconButton onClick={onClick}>
        {type === 'pdf' ? <PdfIcon /> : <ImagePlaceholderIcon />}
      </IconButton>
    </TyTooltip>
  );
};
