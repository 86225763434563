import classNames from 'classnames';
import { ComponentProps } from 'react';
import { textStyles } from '../style/text-styles';
import { ButtonWithLoading } from './ButtonWithLoading';

export const PrimaryButton = ({
  className,
  ...props
}: ComponentProps<typeof ButtonWithLoading>) => (
  <ButtonWithLoading
    className={classNames(
      props.disabled && [
        'bg-buttons-back-disabled',
        'text-buttons-front-disabled',
      ],
      !props.disabled && [
        'bg-buttons-back-primary',
        'hover:border-buttons-back-primary-hover',
        'hover:bg-buttons-back-primary-hover',
        'text-buttons-front-primary-text',
      ],
      'transition',
      'ease-in',
      'relative',
      'rounded-3xl',
      'py-2 px-4',
      textStyles.cButtonMd,
      className
    )}
    type="submit"
    {...props}
  />
);
