import { BadgePill } from '@tymbe/ty-components/indicators';
import { ComponentProps } from 'react';
import { ApplicationState } from '../../types/TymbeApi';
import { ApplicationStateDisplay } from '../../utils/enums';

type ApplicationStateBadgePill = Omit<
  ComponentProps<typeof BadgePill>,
  'variant'
> & {
  state: ApplicationState;
};

export const ApplicationStateBadgePill = ({
  state,
  ...rest
}: ApplicationStateBadgePill) => {
  const value = ApplicationStateDisplay[state];

  switch (state) {
    case ApplicationState.CONFIRMED:
      return (
        <BadgePill variant="success" {...rest}>
          {value}
        </BadgePill>
      );
    case ApplicationState.CANCELED_LATE:
      return (
        <BadgePill variant="error" {...rest}>
          {value}
        </BadgePill>
      );
    case ApplicationState.CANCELED_EARLY:
      return (
        <BadgePill variant="warning" {...rest}>
          {value}
        </BadgePill>
      );
    case ApplicationState.SYSTEM_CANCELED:
      return (
        <BadgePill variant="warning" {...rest}>
          {value}
        </BadgePill>
      );
    case ApplicationState.REJECTED:
      return (
        <BadgePill variant="warning" {...rest}>
          {value}
        </BadgePill>
      );
    default:
      return (
        <BadgePill variant="default" {...rest}>
          {value}
        </BadgePill>
      );
  }
};
