import classNames from 'classnames';
import React from 'react';
import { textStyles } from '../style/text-styles';

type paragraphVariants =
  | 'body1Sb'
  | 'body1Md'
  | 'body1Rg'
  | 'body2Sb'
  | 'body2Md'
  | 'body2Rg'
  | 'captionSb'
  | 'captionMd'
  | 'captionRg';

interface paragraphProps extends React.ComponentPropsWithoutRef<'p'> {
  variant?: paragraphVariants;
}

const variantStyleMap: Record<paragraphVariants, string> = {
  body1Sb: textStyles.pBody1Sb,
  body1Md: textStyles.pBody1Md,
  body1Rg: textStyles.pBody1Rg,
  body2Sb: textStyles.pBody2Sb,
  body2Md: textStyles.pBody2Md,
  body2Rg: textStyles.pBody2Rg,
  captionSb: textStyles.pCaptionSb,
  captionMd: textStyles.pCaptionMd,
  captionRg: textStyles.pCaptionRg,
};

export const Paragraph = ({
  className = '',
  variant,
  ...props
}: paragraphProps) => {
  const variantStyle = variant ? variantStyleMap[variant] : '';

  return <p className={classNames(variantStyle, className)} {...props} />;
};
