export const TimerIcon = ({ className = '' }) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className={`${className} h-[20px] w-[20px]`}
  >
    <path
      d="M8.3339 2.91667H11.6672C12.1256 2.91667 12.5006 2.54167 12.5006 2.08333C12.5006 1.625 12.1256 1.25 11.6672 1.25H8.3339C7.87557 1.25 7.50057 1.625 7.50057 2.08333C7.50057 2.54167 7.87557 2.91667 8.3339 2.91667Z"
      fill="#DC2626"
    />
    <path d="M15.8589 6.575L16.4839 5.95C16.8006 5.63333 16.8089 5.10833 16.4839 4.78333L16.4756 4.775C16.1506 4.45 15.6339 4.45833 15.3089 4.775L14.6839 5.4C13.3922 4.36667 11.7672 3.75 10.0006 3.75C6.00057 3.75 2.60057 7.05 2.50057 11.05C2.39224 15.2833 5.78391 18.75 10.0006 18.75C14.1506 18.75 17.5006 15.3917 17.5006 11.25C17.5006 9.48333 16.8839 7.85833 15.8589 6.575ZM10.8339 11.25C10.8339 11.7083 10.4589 12.0833 10.0006 12.0833C9.54224 12.0833 9.16724 11.7083 9.16724 11.25V7.91667C9.16724 7.45833 9.54224 7.08333 10.0006 7.08333C10.4589 7.08333 10.8339 7.45833 10.8339 7.91667V11.25Z" />
  </svg>
);
