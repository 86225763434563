import classNames from 'classnames';
import { NavTabs } from '@tymbe/components/navigation';
import { ComponentProps } from 'react';

export const TyNavTabs = ({
  containerClassName = '',
  tabIndicatorClassName = '',
  ...props
}: ComponentProps<typeof NavTabs>) => {
  return (
    <NavTabs
      containerClassName={classNames(
        'flex relative gap-10 py-5 px-10',
        containerClassName
      )}
      tabIndicatorClassName={classNames(
        'absolute h-1 rounded-t transition-all duration-500 ease-in-out bg-base-front-active bottom-0 left-0',
        tabIndicatorClassName
      )}
      {...props}
    />
  );
};
