import { nanoid } from '@reduxjs/toolkit';
import React, { useRef, useState, useEffect, DependencyList, FC } from 'react';
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import canvasPreview from '../../utils/canvasPreview';
import { IMAGE_ASPECT_RECTANGLE } from '../../utils/constants';

export function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps: DependencyList = [],
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn();
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, waitTime, ...deps]);
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

export type ImageCropProps = {
  image: string;
  onChange: (blob: Blob) => void;
  id?: string;
  aspectHeight?: number;
  aspectWidth?: number;
  maxUploadImageHeight?: number;
  maxUploadImageWidth?: number;
  allowScale?: boolean;
  allowRotate?: boolean;
};

const ImageCrop: FC<ImageCropProps> = ({
  id = nanoid(),
  allowRotate = false,
  allowScale = false,
  image,
  aspectWidth = IMAGE_ASPECT_RECTANGLE.width,
  aspectHeight = IMAGE_ASPECT_RECTANGLE.height,
  maxUploadImageWidth = 600,
  maxUploadImageHeight = 200,
  onChange,
}: ImageCropProps) => {
  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>(
    {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      unit: 'px',
    },
  );
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect] = useState<number | undefined>(aspectWidth / aspectHeight);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  // We use this debounce with dependecies to avoid unnecesary updates
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width
        && completedCrop?.height
        && imageRef.current
        && previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imageRef.current,
          previewCanvasRef.current,
          completedCrop,
          onChange,
          scale,
          rotate,
        );
      }
    },
    500,
    [completedCrop, scale, rotate],
  );

  useEffect(() => {
    setCrop(undefined); // Makes crop preview update between images.
    setImgSrc(image);
  }, [image]);

  const onSetCompletedCrop = (croppedImage: PixelCrop) => {
    setCompletedCrop(croppedImage);
  };
  return (
    <div>
      <div className="text-align-center">
        <div>
          <div
            className="text-align-center"
            style={{
              width: maxUploadImageWidth,
              height: maxUploadImageHeight,
            }}
          >
            {!completedCrop ? null : (
              <canvas
                ref={previewCanvasRef}
                className="ty-image-crop-container"
                style={{
                  height: maxUploadImageHeight,
                }}
              />
            )}
          </div>
        </div>
        <br />

        {!allowRotate ? null : (
          <div className="ty-image-crop-control">
            <div>
              <label htmlFor={`${id}-rotate-input`}>Rotace: </label>
              <input
                id={`${id}-rotate-input`}
                type="range"
                min={-180}
                max={180}
                step={1}
                value={rotate}
                title={`${rotate}`}
                disabled={!imgSrc}
                onBlur={() => onSetCompletedCrop(completedCrop)}
                onChange={(e) => setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))}
              />
              <span>
                {rotate}°
              </span>
            </div>
          </div>
        )}
        {allowScale && (
          <div className="ty-image-crop-control">
            <div><label htmlFor={`${id}-scale-input`}>Měřítko: </label>
              <input
                id={`${id}-scale-input`}
                type="range"
                min={0.1}
                max={5}
                step="0.1"
                value={scale}
                disabled={!imgSrc}
                onBlur={() => onSetCompletedCrop(completedCrop)}
                onChange={(e) => setScale(Number(e.target.value))}
              />
              <span>
                {scale}
              </span>
            </div>
          </div>
        )}
        <br />
        {imgSrc ? (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => onSetCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imageRef}
              alt="Crop me"
              src={imgSrc}
              className="w-auto"
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                height: maxUploadImageHeight,
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        ) : null}
      </div>
    </div>
  );
};
export default ImageCrop;
