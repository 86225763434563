import { Button } from '@tymbe/components/button';
import { ComponentProps } from 'react';

export const IconButton = ({
  className,
  ...props
}: ComponentProps<typeof Button>) => (
  <Button
    className={`${className} outline-none p-0 rounded cursor-pointer`}
    type="button"
    {...props}
  />
);
