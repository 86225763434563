import { ComponentProps } from 'react';
import { TyImageViewer } from './ImageViewer';
import { EmptyImageIcon } from '../icons';
import classNames from 'classnames';

export const TyCompanyLogo = ({
  className = '',
  ...props
}: ComponentProps<typeof TyImageViewer>) => {
  return (
    <div
      className={classNames(
        className,
        'rounded-md border border-base-back-3 justify-center items-center flex',
        '[&>img]:rounded-md'
      )}
    >
      <TyImageViewer
        fileErrorIndicator={<EmptyImageIcon className="text-base-back-3 m-1" />}
        {...props}
      />
    </div>
  );
};
