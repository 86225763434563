import { Moment } from 'moment';
import { useSearchParams } from 'react-router-dom';
import { Options } from 'react-select';

import { queryToParamsSwitch, paramsToQuerySwitch } from './utils';
import { Option } from '../../components/TyAdvancedSearch/Libs/transform';
import { CompanyData } from '../../types/TymbeApi';

interface TyUrlParams {
  urlParams: URLSearchParams;
  tyAdvanceSearch: TyAdvancedSearchObject;
  allSearchParams: TyUrlInputObject;
}

interface SetUrlParamsHandlerParams {
  (inputObject: TyUrlInputObject): void;
}

export interface TyAdvancedSearchObject {
  tymber?: Options<Option>;
  accountState?: string;
  userType?: string;
  gender?: string;
  nationality?: Options<Option>;
  email?: Options<Option>;
  phone?: Options<Option>;
  socialSecurityNumber?: Options<Option>;
  ageFrom?: Option;
  ageTo?: Option;
  bankAccount?: Options<Option>;
  address?: Options<Option>;
  companyBlock?: Options<Option>;
  registrationDateFrom?: Moment;
  registrationDateTo?: Moment;
  personNote?: Options<Option>;
  shiftTimeFrom?: Moment;
  shiftTimeTo?: Moment;
  shiftDateFrom?: Moment;
  shiftDateTo?: Moment;
  company?: Options<Option>;
  branchoffice?: Options<Option>;
  applicationState?: Option;
  orderId?: Options<Option>;
  applicationId?: Options<Option>;
  position?: Options<Option>;
  attendanceId?: Options<Option>;
  applicationPriceFrom?: Option;
  applicationPriceTo?: Option;
  applicationCreditsFrom?: Option;
  applicationCreditsTo?: Option;
  internalName?: Option;
  showSystemCancelled?: boolean;
  score?: Option;
  onlyValidDocuments?: boolean;
  dateYearFormat?: Moment;
  dateMonthFormat?: Moment;
  department?: Options<Option>;
}

interface TyUrlInputObject extends TyAdvancedSearchObject {
  search?: string;
  page?: number;
  pageSize?: number;
  sort?: Record<string, number>;
  dateRange?: string[];
  year?: number;
  month?: { label: string; value: number; };
  companyData?: CompanyData | CompanyData[];
  documentTypes?: Option[];
  showPublished?: boolean;
  showUnpublished?: boolean;
  onlyUnresolved?: boolean;
}

const useURLParamsHandler = (): [
  TyUrlParams,
  SetUrlParamsHandlerParams,
] => {
  const [urlParams, setUrlParams] = useSearchParams();
  const advanceSearchKeys = ['tymber', 'accountState', 'userType', 'gender', 'nationality', 'email',
    'phone', 'socialSecurityNumber', 'ageFrom', 'ageTo', 'bankAccount', 'address', 'companyBlock',
    'registrationDateFrom', 'registrationDateTo', 'personNote', 'shiftTimeFrom', 'shiftTimeTo', 'shiftDateFrom',
    'shiftDateTo', 'company', 'branchoffice', 'applicationState', 'orderId', 'applicationId', 'position',
    'attendanceId', 'applicationPriceFrom', 'applicationPriceTo', 'applicationCreditsFrom', 'applicationCreditsTo',
    'internalName', 'showSystemCancelled', 'score', 'onlyValidDocuments', 'dateYearFormat', 'dateMonthFormat',
    'department',
  ];

  const existingEntries = Object.fromEntries(urlParams.entries());

  const setUrlParamsHandler = (inputObject: TyUrlInputObject) => {
    if (!Object.keys(inputObject).length) {
      Array.from(urlParams.keys()).forEach((key) => {
        if (advanceSearchKeys.includes(key)) {
          setUrlParams((par) => {
            par.delete(`${key}`);
            return par;
          });
        }
      });
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(inputObject)) {
        if (value === '' || (Array.isArray(value) && !value.length)) {
          setUrlParams((prev) => {
            prev.delete(key);
            return prev;
          });
        } else {
          const escapedValue = queryToParamsSwitch(key, value);
          // Don't set value that already exists
          if (escapedValue === existingEntries[key]) continue;

          setUrlParams((prev) => {
            prev.set(key, escapedValue);
            return prev;
          });
        }
      }
    }
  };

  const getAllParamsFromUrl = () => {
    const searchObj: TyAdvancedSearchObject | any = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of urlParams.entries()) {
      const escapedValue = paramsToQuerySwitch(key, value);
      searchObj[key] = escapedValue;
    }
    return searchObj;
  };
  const allSearchParams: TyUrlInputObject = getAllParamsFromUrl();

  const advancedParamsFromUrl =
  Object.fromEntries(
    Object.entries(allSearchParams).filter(([k]) => advanceSearchKeys.includes(k)),
  );

  return [
    {
      urlParams,
      allSearchParams,
      tyAdvanceSearch: advancedParamsFromUrl,
    },
    setUrlParamsHandler,
  ];
};

export default useURLParamsHandler;
