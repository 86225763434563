import DislikeTwoTone from '@ant-design/icons/DislikeTwoTone';
import LikeTwoTone from '@ant-design/icons/LikeTwoTone';
import { Menu, Tooltip } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { dangerColor, grayBlue, primaryColor, disabledColor } from '../../utils/colors';
import { BAD_JOB_EVALUATION_SCORE, GOOD_JOB_EVALUATION_SCORE } from '../../utils/constants';
import { StarIcon } from '../icons';

export const InvitationModalTriggerButton = ({ row, onClick }) => (
  <div
    className="ty-table-cell-hoverable"
    style={{ display: 'flex', justifyContent: 'space-between' }}
  >
    {row.invitation_orders_count}
    {moment(row.start_time).isAfter(moment()) && (
      <div className="ty-table-cell-controls">
        <button
          type="button"
          className="ty-button-pill"
          onClick={() => {
            onClick(row);
          }}
          style={{ backgroundColor: primaryColor }}
        >
          <div className="h-full w-full">+</div>
        </button>
      </div>
    )}
  </div>
);
InvitationModalTriggerButton.propTypes = {
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const ThumbsRating = ({
  setRating,
  disabled = false,
  defaultColor = grayBlue,
  upColor = primaryColor,
  downColor = dangerColor,
  disabledTooltip = '',
}) => {
  const [likeColor, setLikeColor] = useState(defaultColor);
  const [dislikeColor, setDislikeColor] = useState(defaultColor);
  const onSetRating = (likeClr, dislikeClr, newRating) => {
    if (!disabled) {
      setLikeColor(likeClr);
      setDislikeColor(dislikeClr);
      setRating(newRating);
    }
  };

  useEffect(() => {
    if (disabled) {
      setLikeColor(disabledColor);
      setDislikeColor(disabledColor);
    } else {
      setLikeColor(defaultColor);
      setDislikeColor(defaultColor);
    }
  }, [disabled, defaultColor]);

  return (
    <Tooltip title={disabled ? disabledTooltip : null}>
      <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        <DislikeTwoTone
          twoToneColor={dislikeColor}
          style={{ marginRight: '10px', cursor: disabled ? 'not-allowed' : 'pointer' }}
          className="ty-icon-button"
          onClick={() => { onSetRating(defaultColor, downColor, BAD_JOB_EVALUATION_SCORE); }}
        />
        <LikeTwoTone
          twoToneColor={likeColor}
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          className="ty-icon-button"
          onClick={() => { onSetRating(upColor, defaultColor, GOOD_JOB_EVALUATION_SCORE); }}
        />
      </div>
    </Tooltip>
  );
};
ThumbsRating.propTypes = {
  setRating: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  defaultColor: PropTypes.string,
  upColor: PropTypes.string,
  downColor: PropTypes.string,
  disabledTooltip: PropTypes.string,
};

export const StarRating = ({ rating = 0 }) => (
  <div className="ty-star-rating" style={{ display: 'flex', flexWrap: 'nowrap' }}>
    <StarIcon
      iconcolor={rating >= 1 ? ' #B6CD1E' : null}
      strokecolor={rating >= 1 ? ' #B6CD1E' : null}
    />
    <StarIcon
      iconcolor={rating >= 2 ? ' #B6CD1E' : null}
      strokecolor={rating >= 2 ? ' #B6CD1E' : null}
    />
    <StarIcon
      iconcolor={rating >= 3 ? ' #B6CD1E' : null}
      strokecolor={rating >= 3 ? ' #B6CD1E' : null}
    />
    <StarIcon
      iconcolor={rating >= 4 ? ' #B6CD1E' : null}
      strokecolor={rating >= 4 ? ' #B6CD1E' : null}
    />
    <StarIcon
      iconcolor={rating >= 5 ? ' #B6CD1E' : null}
      strokecolor={rating >= 5 ? ' #B6CD1E' : null}
    />
  </div>
);
StarRating.propTypes = {
  rating: PropTypes.number,
};

export const AttendanceMenu = ({ record }) => (
  <Menu className="ty-admin-dropdown-menu">
    <Menu.Item>
      <Link
        to={{
          pathname: `/user/${record.person.id}`,
          tymberProps: { user: { ...record.person, rating: record.rating } },
        }}
        rel="noopener noreferrer"
      >
        Detail Tymbera
      </Link>
    </Menu.Item>
  </Menu>
);
AttendanceMenu.propTypes = {
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
};
