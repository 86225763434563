import { ComponentProps } from 'react';
import Spinner from '../indicators/Spinner';
import { TyTableCell } from './TableCell';
import { TyTableRow } from './TableRow';

type TableRowSpinnerProps = {
  colSpan: number;
  visible?: boolean;
} & ComponentProps<typeof TyTableRow>;

export const TableRowSpinner = ({
  colSpan,
  visible = false,
  ...rest
}: TableRowSpinnerProps) => {
  if (!visible) return null;

  return (
    <TyTableRow {...rest}>
      <TyTableCell className="py-4" align="center" colSpan={colSpan}>
        <Spinner className='w-5 h-5'/>
      </TyTableCell>
    </TyTableRow>
  );
};
