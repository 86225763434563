import { FieldProps, useField } from 'informed';
import React from 'react';
import RS, { GroupBase, PropsValue } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

import './TyInput.css';

import CustomOption from './CustomOption';
import TyInputError from './TyInputError';

type Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> =
  StateManagerProps<Option, IsMulti, Group>;

type DivProps = { label?: string & React.HTMLProps<HTMLDivElement> };

export type TySelectProps<Option, Fields extends object, IsMulti extends boolean, Group extends GroupBase<Option>> =
  Omit<Props<Option, IsMulti, Group>, keyof FieldProps<DivProps, Option, Fields>>
  & FieldProps<DivProps, Option, Fields>;

// We need to export the component directly (avoid defualt) to avoid
// bug that is in vite currently, we also have to manually import
// the `ReactSelect` component, viz:
// https://github.com/vitejs/vite/issues/2139

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReactSelect = (RS as any).default ? (RS as any).default : RS;

// eslint-disable-next-line import/prefer-default-export
export const TySelect = <Fields extends object, Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: TySelectProps<Option, Fields, IsMulti, Group>,
) => {
  const {
    fieldState,
    userProps,
    fieldApi,
    render,
  } = useField<typeof props, PropsValue<Option>>({ ...props });
  const {
    id,
    label,
    className,
    placeholder = '',
    options = [],
    isClearable,
    noOptionsMessage = () => 'Žádné výsledky',
    loadingMessage = () => 'Načítání',
    required = false,
    validate,
    isDisabled = false,
    defaultValue,
    ...rest
  } = userProps;
  const { error, showError, focused, value } = fieldState;
  const { setValue, setTouched, setFocused } = fieldApi;

  const isFocusedClassName = (classN: string) => {
    if (focused) return classN;
    if (rest.isMulti && (value as unknown[])?.length) return classN;
    if (!rest.isMulti && value) return classN;
    return '';
  };

  const isDisabledClassName = isDisabled ? 'ty_input_field--is-disabled' : '';

  return render(
    <div className={`ty_input_field ty_select ${showError && error ? 'ty_input_error' : ''} ${className || ''} ${isDisabledClassName}`}>
      <ReactSelect<Option, IsMulti, Group>
        {...rest}
        defaultValue={defaultValue}
        inputId={id}
        placeholder={placeholder}
        className={`ty_select__container ty_input_select ${isFocusedClassName('ty_input_select_focused')}`}
        classNamePrefix="ty_select"
        components={{
          // we have no control over naming convention in external library
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Option: CustomOption,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          DropdownIndicator: () => null,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          IndicatorSeparator: () => null,
        }}
        onChange={(e: PropsValue<Option> | undefined) => setValue(e)}
        onBlur={(e: React.SyntheticEvent<Element, Event> | undefined) => {
          setFocused(false, e);
          setTouched(true, e);
        }}
        onFocus={(e: React.SyntheticEvent<Element, Event> | undefined) => setFocused(true, e)}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        maxMenuHeight={250}
        isClearable={isClearable}
        options={options}
        value={value || null}
        isDisabled={isDisabled}
        openMenuOnFocus
        aria-invalid={showError}
        aria-describedby={`${id}-error`}
      />
      {label ? <label htmlFor={id}>{label}</label> : null}
      <TyInputError
        fieldState={fieldState}
        required={required}
        validate={validate}
      />
    </div>,
  );
};
