import { TableHead } from '@tymbe/components/table';
import classNames from 'classnames';
import { ComponentProps } from 'react';
import { textStyles } from '../style/text-styles';

export const TyTableHead = ({
  className,
  ...props
}: ComponentProps<typeof TableHead>) => (
  <TableHead
    className={classNames(
      className,
      'border border-special-outline-divider px-3 py-6',
      textStyles.pBody2Sb
    )}
    {...props}
  />
);
