export const NUMERIC_LOCALE = 'cs';
export const UPLOAD_MAX_FILE_SIZE = 5 * 1024 ** 2;
export const HOT_DEAL_TRIGGER_HOURS = 4;
export const DEFAULT_PAGE_SIZES = [10, 20, 50];
export const DEFAULT_BILLING_RATE = 1.34;
export const DEFAULT_PAGINATION_PROPS = {
  showSizeChanger: true,
  pageSizeOptions: DEFAULT_PAGE_SIZES,
};
export const IMAGE_ASPECT_RECTANGLE = {
  width: 19,
  height: 11,
};

export const IMAGE_ASPECT_SQUARE = {
  width: 1,
  height: 1,
};

export const DEFAULT_SORT = { created_at: -1 };
export const IMAGE_CROP_PREFIX = 'ty-image-crop';
export const IMAGE_INIT_ZOOM = 1;
export const IMAGE_ZOOM_STEP = 0.1;
export const IMAGE_INIT_ROTATE = 0;
export const IMAGE_ROTATE_STEP = 1;
export const IMAGE_MIN_ROTATE = -180;
export const IMAGE_MAX_ROTATE = 180;
export const DEFAULT_JOB_EVALUATION_SCORE = 49;
export const GOOD_JOB_EVALUATION_SCORE = 99;
export const BAD_JOB_EVALUATION_SCORE = 0;
