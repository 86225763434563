import { AbsenceState } from '@tymbe/schema/enums';
import { BadgePillRadio } from '@tymbe/ty-components/inputs/radio/badgePill';
import { TyRadioGroup } from '@tymbe/ty-components/inputs/radio/';

type AbsenceStateActionsProps = {
  name: string;
};

export const AbsenceStateActions = ({ name }: AbsenceStateActionsProps) => (
  <TyRadioGroup name={name} className="flex">
    <BadgePillRadio
      label="Schválit"
      value={AbsenceState.APPROVED}
      variant="success"
    />
    <BadgePillRadio
      label="Zamítnout"
      value={AbsenceState.REJECTED}
      variant="error"
    />
    <BadgePillRadio
      label="Upřesnit"
      value={AbsenceState.CLARIFICATION_NEEDED}
      variant="default"
    />
  </TyRadioGroup>
);
