import { MutableRefObject, useEffect } from 'react';
import { FieldProps, TextAreaProps as InformedProps, useField } from 'informed';

type TextAreaProps<Fields extends object> = FieldProps<
  InformedProps,
  string,
  Fields
>;

// Logic that handles textArea resizing based on textArea value and rows
const useAutosizeTextArea = (
  textAreaRef: MutableRefObject<HTMLTextAreaElement | undefined>,
  value: string
) => {
  const currentRef = textAreaRef?.current;

  useEffect(() => {
    if (currentRef) {
      const minHeight = currentRef.rows * Number(currentRef.style.fontSize);
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      currentRef.style.height = '0px';
      const { scrollHeight } = currentRef;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      currentRef.style.height = `${
        scrollHeight >= minHeight ? scrollHeight : minHeight
      }px`;
    }
  }, [currentRef, value, currentRef?.rows]);
};

export const TextArea = <Fields extends object>({
  disabled = false,
  ...props
}: TextAreaProps<Fields>) => {
  const { fieldState, userProps, fieldApi, ref } = useField<
    typeof props,
    string
  >({ ...props });
  const { id, label, className, placeholder = ' ', ...rest } = userProps;
  const { showError, maskedValue, value } = fieldState;
  const { setValue, setTouched, setFocused } = fieldApi;

  useAutosizeTextArea(ref, value);

  return (
    <textarea
      ref={ref}
      id={id}
      placeholder={placeholder}
      {...rest}
      value={!maskedValue ? '' : (maskedValue as string)}
      onChange={(e) => setValue(e.target.value, e)}
      onBlur={(e) => {
        setTouched(true, e);
        setFocused(false, e);
      }}
      onFocus={(e) => setFocused(true, e)}
      aria-invalid={showError}
      rows={props.rows}
      className={`outline-none w-full ${className}`}
    />
  );
};
