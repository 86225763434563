import classNames from 'classnames';
import React from 'react';
import { textStyles } from '../style/text-styles';

export const H1 = ({
  className = '',
  ...props
}: React.ComponentPropsWithoutRef<'h1'>) => {
  return <h1 className={classNames(textStyles.h1, className)} {...props} />;
};

export const H2 = ({
  className = '',
  ...props
}: React.ComponentPropsWithoutRef<'h2'>) => {
  return <h2 className={classNames(textStyles.h2, className)} {...props} />;
};

export const H3 = ({
  className = '',
  ...props
}: React.ComponentPropsWithoutRef<'h3'>) => {
  return <h3 className={classNames(textStyles.h3, className)} {...props} />;
};

export const H4 = ({
  className = '',
  ...props
}: React.ComponentPropsWithoutRef<'h4'>) => {
  return <h4 className={classNames(textStyles.h4, className)} {...props} />;
};
