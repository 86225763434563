import classNames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';

export const TyTableFooter = ({
  className = '',
  ...props
}: ComponentPropsWithoutRef<'div'>) => (
  <div
    className={classNames(
      className,
      'py-2',
      'px-10',
      'bg-base-back-1',
      'rounded-b-3xl',
      'border-x-1 border-x-special-outline-divider border-y-0',
      'shadow-md'
    )}
    {...props}
  />
);
