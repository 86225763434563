import { Button } from '@tymbe/components/button';
import classNames from 'classnames';
import { Children, ComponentProps, useState } from 'react';
import { ChevronDownIcon } from '../icons';
import { textStyles } from '../style/text-styles';

type Props = {
  threshold?: number;
} & ComponentProps<typeof Button>;

export const ShowAllButton = ({ threshold = 2, children }: Props) => {
  const [showAll, setShowAll] = useState(false);
  const flatChildren = Children.toArray(children);

  return (
    <>
      {flatChildren.slice(0, showAll ? flatChildren.length : threshold)}
      {flatChildren.length > threshold && (
        <Button
          type="button"
          className={classNames(
            textStyles.pCaptionMd,
            'text-base-front-3 flex items-center p-0 my-4 outline-none'
          )}
          onClick={() => setShowAll(!showAll)}
        >
          {
            <ChevronDownIcon
              className={`${showAll ? 'rotate-180' : ''} fill-base-front-3`}
            />
          }
          {showAll ? 'Skrýt všechny' : 'Zobrazit všechny'}
        </Button>
      )}
    </>
  );
};
