import auth from '@feathersjs/authentication-client';
import feathers from '@feathersjs/client';
import { HookContext, Service } from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import { AttendanceData } from '@tymbe/schema/attendance.interface';
import { AuditlogData } from '@tymbe/schema/auditlog.interface';
import { MailData } from '@tymbe/schema/mail.interface';
import { MpsvReportData } from '@tymbe/schema/mpsv-report.interface';
import { PaymentRequestData } from '@tymbe/schema/payment-request.interface';
import { PaymentStatusData } from '@tymbe/schema/payment-status.interface';
import { PayoutInfoData } from '@tymbe/schema/payout-info.interface';
import { PersonNotificationData } from '@tymbe/schema/person-notification.interface';
import { PersonSalaryLimitData } from '@tymbe/schema/person-salary-limit.interface';
import { PersonUtilityData } from '@tymbe/schema/person-utility.interface';
import { ReleaseAdvancesData } from '@tymbe/schema/release-advances.interface';
import { ReleaseTymberAdvancesData } from '@tymbe/schema/release-tymber-advances.interface';
import { SalaryAdvanceRestrictionsData } from '@tymbe/schema/salary-advance-restrictions.interface';
import { SalaryCalculationData } from '@tymbe/schema/salary-calculation.interface';
import { UtilityData } from '@tymbe/schema/utility.interface';
import { VacationApprovedData } from '@tymbe/schema/vacation-approved.interface';
import axios from 'axios';

import CustomAxios from './CustomAxios';
import getQueryArrayLength from './hook/getQueryArrayLength';
import { ErrorAlert } from '../components/alerts';
import history from '../navigator';
import {
  ApplicationData,
  CompanyData,
  DocumentTypeData,
  LoginData,
  ManShiftData,
  PerkData,
  PersonData,
  PersonDataData,
  PositionData,
  RolesData,
  ShiftData,
  ShiftTemplateData,
} from '../types/TymbeApi';
import { ApplicationExcuseData } from '@tymbe/schema/src/application-excuse.interface';

declare module '@feathersjs/authentication' {
  export interface AuthenticationResult {
    // type of result returned by app.authenticate() and app.reAuthenticate()
    user: LoginData & {
      person: PersonData;
      role: RolesData[];
    };
  }
}
export interface ServiceTypes {
  'application': Service<ApplicationData>;
  'attendance': Service<AttendanceData>;
  'man-shift': Service<ManShiftData>;
  'perk': Service<PerkData>;
  'role': Service<RolesData>;
  'shift-template': Service<ShiftTemplateData>;
  'shift': Service<ShiftData>;
  'person': Service<PersonData>;
  'person-data': Service<PersonDataData>;
  'person-salary-limit': Service<PersonSalaryLimitData>;
  'person-notification': Service<PersonNotificationData>;
  'salary-calculation': Service<SalaryCalculationData>;
  'salary-advance-restrictions': Service<SalaryAdvanceRestrictionsData>;
  'release-advances': Service<ReleaseAdvancesData>;
  'position': Service<PositionData>;
  'payment-status': Service<PaymentStatusData>;
  'payment-request': Service<PaymentRequestData>;
  'payout-info': Service<PayoutInfoData>;
  'mail': Service<MailData>;
  'vacation-approved': Service<VacationApprovedData>;
  'utility': Service<UtilityData>;
  'person-utility': Service<PersonUtilityData>;
  'document-type': Service<DocumentTypeData>;
  'company': Service<CompanyData>;
  'mpsv-report': Service<MpsvReportData>;
  'mpsv/generate-report': Service<MpsvReportData>;
  'auditlog': Service<AuditlogData>;
  'release-tymber-advances': Service<ReleaseTymberAdvancesData>;
  'application-excuse': Service<ApplicationExcuseData>;
  // fall back to the default service type for any other service
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [S: string]: Service<any>;
}

const restClient = rest(import.meta.env.CLIENT_API_URL);
const feathersClient = feathers<ServiceTypes>();

feathersClient.configure(restClient.axios(axios, CustomAxios));
feathersClient.configure(feathers.authentication());
// feathersClient.configure(feathers.ACTIVATE_HOOKS)
feathersClient.configure(
  auth({
    storage: window.localStorage,
    storageKey: 'feathers-react-jwt',
  }),
);

const cache: Partial<{ [K: string]: Promise<unknown>; }> = {};

feathersClient.hooks({
  before: [
    (hook: HookContext) => {
      // eslint-disable-next-line no-param-reassign
      hook.params.updateCache = () => { };
    },
    (hook: HookContext) => {
      hook.params.updateCache();
      if (!hook || !Object.hasOwn(hook, 'arguments')) return hook;
      if (!Array.isArray(hook.arguments)) return hook;
      if (hook.arguments.length === 0) return hook;
      // Check if any of the arguments contains the array modifiers
      if (!['$in', '$nin', '$and', '$or', '$select'].some((el) => JSON.stringify(hook.arguments).includes(el))) {
        return hook;
      }

      const maxQueryLimit = import.meta.env.CLIENT_FEATHERS_MAX_ARRAY_LIMIT || 21;

      hook.arguments.forEach((args) => {
        if (!args || !Object.hasOwn(args, 'query')) return;
        const { query } = args;
        const queryErrs: string[] = getQueryArrayLength(query, maxQueryLimit);
        if (queryErrs.length > 0) {
          ErrorAlert(
            'Došlo k chybě při vytváření dotazu pro komunikaci se serverem. Dotaz obsahuje příliš rozsáhlé pole.',
          );
          throw new Error(`Json array may include up to ${maxQueryLimit} elements, not more! Affected query properties: [${queryErrs.join(',')}]`);
        }
      });
      return hook;
    },
    async (hook: HookContext) => {
      if (!['find', 'get'].includes(hook.method)) return hook;
      const key: string = JSON.stringify([hook.method, hook.path, hook.id, hook.params, hook.data]);

      if (cache[key]) {
      // eslint-disable-next-line no-param-reassign
        hook.result = await cache[key];
      } else {
        cache[key] = new Promise((resolve, reject) => {
        // eslint-disable-next-line no-param-reassign
          hook.params.updateCache = () => {
            delete cache[key];
            if (hook.error) {
              reject(hook.error);
            } else {
              resolve(hook.result);
            }
          };
        });
      }
      return hook;
    },
  ],
  after: (hook) => hook.params.updateCache(),
  error: [
    (hook) => hook.params.updateCache?.(),
    (hook) => {
      if (hook.error.code !== 401 || history.location.pathname === '/signin') return hook;

      history.push('/signin');
      return hook;
    },
  ],
});

export default feathersClient;
