import { Button } from '@tymbe/components/button';
import { ComponentProps } from 'react';
import classNames from 'classnames';
import { textStyles } from '../style/text-styles';
import { TyRadio } from '../inputs/radio/Radio';
import { TyRadioGroup } from '../inputs/radio';

export const PaginationButton = (props: ComponentProps<typeof Button>) => (
  <Button
    className={classNames(
      'px-2 py-0 b-none',
      'text-base-front-3',
      textStyles.pCaptionRg
    )}
    type="button"
    {...props}
  />
);

export const PaginationRadio = (props: ComponentProps<typeof TyRadio>) => (
  <TyRadio
    className={classNames(
      'text-base-front-3',
      textStyles.pCaptionRg,
      'hover:cursor-pointer',
      'has-[:checked]:text-base-front-1',
      'has-[:checked]:bg-base-back-2',
      '[&>input]:appearance-none',
      'px-2.5 py-2'
    )}
    {...props}
  />
);

export const PaginationRadioGroup = (
  props: ComponentProps<typeof TyRadioGroup>
) => (
  <TyRadioGroup
    className="
    inline-flex
    justify-center
    items-center
    bg-base-front-inverse
    rounded-lg
    shadow-xs
    h-8
    "
    {...props}
  />
);
