import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';
import { PaySupplementRules } from '@tymbe/schema/pay-supplement.interface';
import { useFormState } from 'informed';

import { PaySupplementAmountTypeDisplay } from '../../utils/enums';

interface PaySupplementDefaultSettingsCheckProps {
  defaultSettings?: PaySupplementRules | null;
  type: PaySupplementType;
}

const appliesToEntireShiftText = (value?: boolean) => {
  if (value) {
    return 'Platí na celou směnu';
  }
  return 'Neplatí na celou směnu';
};

const PaySupplementDefaultSettingsCheck = (
  { defaultSettings, type }: PaySupplementDefaultSettingsCheckProps,
) => {
  const { values: actualValues } = useFormState<{ pay_supplement: PaySupplementRules }>();

  if (!defaultSettings || (!actualValues.pay_supplement && !Object.hasOwn(defaultSettings, type))) return null;

  if (Object.hasOwn(actualValues.pay_supplement, type) && !Object.hasOwn(defaultSettings, type)) {
    return (
      <div className="text-xs text-danger p-1">
        Ve výchozím nastavení není tento příplatek vyplněn.
      </div>
    );
  }

  if (!Object.hasOwn(actualValues.pay_supplement, type) && Object.hasOwn(defaultSettings, type)) {
    return (
      <div className="text-xs text-danger p-1">
        Ve výchozím nastavení je tento příplatek vyplněn.
      </div>
    );
  }
  // when changing paySupplement settings we want to see all the changes listed beneath the input
  return (
    <>
      {(actualValues.pay_supplement[type] && defaultSettings[type]?.value !== actualValues.pay_supplement[type]?.value)
        && (
          <div className="text-xs text-danger p-1">
            Výchozí hodnota příplatku je {defaultSettings[type]?.value}
          </div>
        )}
      {(defaultSettings[type] && defaultSettings[type]?.type !== actualValues.pay_supplement[type]?.type)
        && (
          <div className="text-xs text-danger p-1">
            Výchozí typ příplatku je {PaySupplementAmountTypeDisplay[defaultSettings[type]!.type]}
          </div>
        )}
      {(defaultSettings[type]?.applies_to_entire_shift !== actualValues.pay_supplement[type]?.applies_to_entire_shift)
        && (
          <div className="text-xs text-danger p-1">
            Výchozí nastavení {appliesToEntireShiftText(defaultSettings[type]?.applies_to_entire_shift)}
          </div>
        )}
    </>
  );
};

export default PaySupplementDefaultSettingsCheck;
