import { useField, FieldProps } from 'informed';
import React, { ComponentProps, ComponentType } from 'react';
import RS, { GroupBase, PropsValue } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { CustomOption } from './CustomOption';

type Props<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> = StateManagerProps<Option, IsMulti, Group>;

type CustomProps = {
  label?: string;
  dropDownIndicator?: React.ReactNode;
  CustomOptionElement: ComponentType<ComponentProps<typeof CustomOption>>;
} & React.HTMLProps<HTMLDivElement>;

export type SelectProps<
  Option,
  Fields extends object,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> = Omit<
  Props<Option, IsMulti, Group>,
  keyof FieldProps<CustomProps, Option, Fields>
> &
  FieldProps<CustomProps, Option, Fields>;

// We need to export the component directly (avoid defualt) to avoid
// bug that is in vite currently, we also have to manually import
// the `ReactSelect` component, viz:
// https://github.com/vitejs/vite/issues/2139

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReactSelect = (RS as any).default ? (RS as any).default : RS;

export const Select = <
  Fields extends object,
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>({
  ...props
}: SelectProps<Option, Fields, IsMulti, Group>) => {
  const { fieldState, userProps, fieldApi, render } = useField<
    typeof props,
    PropsValue<Option>
  >({ ...props });
  const {
    id,
    label,
    classNames,
    placeholder = '',
    options = [],
    isClearable,
    noOptionsMessage = () => 'Žádné výsledky',
    loadingMessage = () => 'Načítání',
    isDisabled = false,
    defaultValue,
    CustomOptionElement,
    dropDownIndicator,
    ...rest
  } = userProps;
  const { showError, value } = fieldState;
  const { setValue, setTouched, setFocused } = fieldApi;

  return render(
    <ReactSelect<Option, IsMulti, Group>
      {...rest}
      defaultValue={defaultValue}
      inputId={id}
      placeholder={placeholder}
      classNames={classNames}
      unstyled
      components={{
        // we have no control over naming convention in external library
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Option: CustomOptionElement,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DropdownIndicator: () => dropDownIndicator,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        IndicatorSeparator: () => null,
      }}
      onChange={(e: PropsValue<Option> | undefined) => setValue(e)}
      onBlur={(e: React.SyntheticEvent<Element, Event> | undefined) => {
        setFocused(false, e);
        setTouched(true, e);
      }}
      onFocus={(e: React.SyntheticEvent<Element, Event> | undefined) =>
        setFocused(true, e)
      }
      noOptionsMessage={noOptionsMessage}
      loadingMessage={loadingMessage}
      maxMenuHeight={250}
      isClearable={isClearable}
      options={options}
      value={value || null}
      isDisabled={isDisabled}
      openMenuOnFocus
      aria-invalid={showError}
      aria-describedby={`${id}-error`}
    />
  );
};
