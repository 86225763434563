import { TableCell } from '@tymbe/components/table';
import classNames from 'classnames';
import { ComponentProps } from 'react';
import { textStyles } from '../style/text-styles';

export const TyTableCell = ({
  className = '',
  ...rest
}: ComponentProps<typeof TableCell>) => (
  <TableCell
    className={classNames(
      'border border-special-outline-divider px-3 py-5',
      textStyles.pBody2Rg,
      className
    )}
    {...rest}
  />
);
