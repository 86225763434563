import { AbsenceState } from '@tymbe/schema/enums';
import { BadgePill } from '@tymbe/ty-components/indicators';
import { ComponentProps } from 'react';

type AbsenceBadgePillProps = Omit<
  ComponentProps<typeof BadgePill>,
  'variant' | 'children'
> & {
  state: AbsenceState;
};

export const AbsenceStateBadgePill = ({
  state,
  ...rest
}: AbsenceBadgePillProps) => {
  switch (state) {
    case AbsenceState.PROCESSING:
      return (
        <BadgePill variant="default" {...rest}>
          Zpracovávame
        </BadgePill>
      );
    case AbsenceState.APPROVED:
      return (
        <BadgePill variant="success" {...rest}>
          Schváleno
        </BadgePill>
      );
    case AbsenceState.REJECTED:
      return (
        <BadgePill variant="error" {...rest}>
          Zamítnuto
        </BadgePill>
      );
    case AbsenceState.CLARIFICATION_NEEDED:
      return (
        <BadgePill variant="default" {...rest}>
          Upřesnění
        </BadgePill>
      );
    case AbsenceState.SYSTEM_APPROVED:
      return (
        <BadgePill variant="success" {...rest}>
          Schváleno bez omluvenky
        </BadgePill>
      );
    case AbsenceState.SYSTEM_REJECTED:
      return (
        <BadgePill variant="error" {...rest}>
          Zamítnuto bez omluvenky
        </BadgePill>
      );
    case AbsenceState.HISTORICAL_UNKNOWN:
      return (
        <BadgePill variant="default" {...rest}>
          Historický dummy záznam
        </BadgePill>
      );
    default:
      return <BadgePill variant="default">{state}</BadgePill>;
  }
};
