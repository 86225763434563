import { ApplicationExcuseData } from '@tymbe/schema/application-excuse.interface';
import { Roles } from '@tymbe/schema/enums';
import Protect from '../../../apiClient/Protect';
import { ApplicationExcuses } from '../../../components/ApplicationExcuse/ApplicationExcuses';

export interface Props {
  excuses: ApplicationExcuseData[];
}

const AbsenceDetails = ({ excuses }: Props) => (
  <Protect
    auth={[
      Roles.SUPER_ADMIN,
      Roles.TYMBE_ADMIN,
      Roles.ADMIN,
      Roles.TYMBE_COORDINATOR,
    ]}
    redirect={false}
  >
    <div className="p-4">
      <ApplicationExcuses
        excuses={excuses}
        titleClassName="text-secondary font-semibold text-sm leading-5"
      />
    </div>
  </Protect>
);

export default AbsenceDetails;
