import { ComponentType } from 'react';
import { ButtonProps } from '../button';

type InputControlsProps = {
  onPlus: () => void;
  onMinus: () => void;
  className?: string;
  IconButtonElement: ComponentType<ButtonProps>;
  plusIcon?: React.ReactNode;
  minusIcon?: React.ReactNode;
};

export const InputControls = ({
  IconButtonElement,
  plusIcon,
  minusIcon,
  onPlus,
  onMinus,
  className,
}: InputControlsProps ) => {
  return (
    <div className={className}>
      <IconButtonElement onClick={onMinus}>
        {minusIcon ?? '-'}
      </IconButtonElement>
      <IconButtonElement onClick={onPlus}>{plusIcon ?? '+'}</IconButtonElement>
    </div>
  );
};
