import {
  DocumentCategory,
  DocumentType,
  DocumentValidityDurationUnit,
  DocumentValidityEnd,
  DocumentValidityStart,
} from '@tymbe/schema/enums';
import { FieldState } from 'informed';
import moment from 'moment-timezone';
import { useState } from 'react';

import TyInput from '../../../../../components/inputs/TyInput';
import { TySelect } from '../../../../../components/inputs/TySelect';
import TySelectBranchoffice from '../../../../../components/inputs/TySelectBranchoffice';
import { BranchofficeData } from '../../../../../types/TymbeApi';

export interface DocumentTypeFormContainerProps {
  companyId: number;
}

export interface DocumentTypeFormContainerFields {
  is_published: { value: boolean },
  name: string | undefined,
  display_name?: string | null,
  assigned_branchoffice: BranchofficeData | undefined | null,
  editorJS: { value: boolean },
  type: { value: DocumentType, label: string },
  category?: { value: DocumentCategory | null },
  validity_start_at?: { value: DocumentValidityStart | null },
  validity_end_at?: { value: DocumentValidityEnd | null },
  validity_duration?: number | null,
  validity_duration_unit?: { value: DocumentValidityDurationUnit | null },
}

export const typeOptions = [
  { value: DocumentType.CONTRACT_DPP, label: 'DPP' },
  { value: DocumentType.CONTRACT_DPC, label: 'DPČ' },
  { value: DocumentType.CONTRACT_HPP, label: 'HPP' },
  { value: DocumentType.DODP_TEMPLATE, label: 'DoDP' },
  { value: DocumentType.OTHER, label: 'Jiné' },
];

export const categoryOptions = [
  { value: DocumentCategory.WAGE_RELATED, label: 'Výpočet výplaty' },
];

export const validityStartOptions = [
  { value: DocumentValidityStart.ON_SIGN, label: 'Při podpisu' },
  { value: DocumentValidityStart.SHIFT_START, label: 'Začátek směny' },
];

export const validityEndOptions = [
  { value: DocumentValidityEnd.DAY_END, label: 'Konec dne' },
  { value: DocumentValidityEnd.MONTH_END, label: 'Konec měsíce' },
  { value: DocumentValidityEnd.YEAR_END, label: 'Konec roku' },
];

export const validityDurationOptions = [
  { value: DocumentValidityDurationUnit.DAY, label: 'Den' },
  { value: DocumentValidityDurationUnit.WEEK, label: 'Týden' },
  { value: DocumentValidityDurationUnit.MONTH, label: 'Měsíc' },
  { value: DocumentValidityDurationUnit.YEAR, label: 'Rok' },
];

const DocumentTypeFormContainer = ({
  companyId,
}: DocumentTypeFormContainerProps) => {
  const defaultBranchofficeOption = [
    {
      id: 0,
      name: 'Pro celou firmu',
    },
  ] as BranchofficeData[];

  const [start, setStart] = useState<DocumentValidityStart>();
  const [end, setEnd] = useState<DocumentValidityEnd>();
  const [duration, setDuration] = useState<number>();
  const [unit, setUnit] = useState<DocumentValidityDurationUnit>();

  const today = moment();
  const documentSign = today.clone().add(1, 'day');
  const shiftStart = today.clone().add(3, 'day');

  const computeStart = () => (start === DocumentValidityStart.SHIFT_START
    ? shiftStart
    : documentSign);

  const computeEnd = () => {
    const result = computeStart().clone();

    if (unit && duration && duration > 0) {
      result.add(duration, unit);
    }

    if (end) {
      result.endOf(end);
    }

    return result;
  };

  return (
    <>
      <h3>Základní údaje</h3>
      <TySelect
        required="Povinné pole"
        name="is_published"
        label="Stav"
        options={[{ value: false }, { value: true }]}
        getOptionLabel={(option) => {
          if (option.value) return 'Publikovat';
          return 'Draft';
        }}
      />

      <TyInput
        name="name"
        type="text"
        required="Název dokumentu je povinný"
        label="Název dokumentu"
      />

      <TyInput
        name="display_name"
        type="text"
        label="Alias / Název pro brigádníky"
      />

      <TySelect
        id="type"
        name="type"
        label="Druh dokumentu"
        options={typeOptions}
        required
      />

      <TySelect
        id="category"
        name="category"
        label="Kategorie dokumentu"
        options={categoryOptions}
        isClearable
      />

      <TySelectBranchoffice
        companyId={companyId}
        type="branchoffice"
        name="assigned_branchoffice"
        label="Přidělené Provozovny"
        debounceTimeout={1000}
        required
      />

      <h3>Platnost dokumentu</h3>
      <TySelect
        id="validity_start_at"
        name="validity_start_at"
        label="Začátek platnosti"
        options={validityStartOptions}
        isClearable
        onChange={(fieldState) => setStart(fieldState.value.value)}
      />

      <TySelect
        id="validity_end_at"
        name="validity_end_at"
        label="Konec platnosti"
        options={validityEndOptions}
        isClearable
        onChange={(fieldState) => setEnd(fieldState.value.value)}
      />

      <div className="flex flex-row gap-2">
        <TyInput
          name="validity_duration"
          type="number"
          min={1}
          label="Délka platnosti"
          onChange={(fieldState: FieldState<number>) => setDuration(fieldState.value)}
        />

        <TySelect
          id="validity_duration_unit"
          name="validity_duration_unit"
          label="Jednotka"
          options={validityDurationOptions}
          isClearable
          onChange={(fieldState) => setUnit(fieldState.value.value)}
        />
      </div>
      <h4>Příklad</h4>
      <div>
        <p>Dnes je: {today.format('DD.MM. YYYY')}</p>
        <p>Směna začína: {shiftStart.format('DD.MM. YYYY')}</p>
        <p>Uživatel podepíše směnu: {documentSign.format('DD.MM. YYYY')}</p>
        <p>
          Platnost dokumentu je od: <b>{computeStart().format('DD.MM. YYYY')}</b>&nbsp;
          do: <b>{computeEnd().format('DD.MM. YYYY')}</b>
        </p>
      </div>
    </>
  );
};

export default DocumentTypeFormContainer;
