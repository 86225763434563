import { KodOssz } from '@tymbe/cssz/enums/kodOssz.enum';
import { GroupBase } from 'react-select';

import { TySelect, TySelectProps } from '../inputs/TySelect';

type Option = {
  label: string;
  value: KodOssz;
};

export const osszCodeOptions: Option[] = [
  { label: 'CSSZ Praha Ústředí', value: KodOssz.CSSZ_PRAHA_USTREDI },
  { label: 'Praha 10', value: KodOssz.PRAHA_10 },
  { label: 'Praha 1', value: KodOssz.PRAHA_1 },
  { label: 'Praha 2', value: KodOssz.PRAHA_2 },
  { label: 'Praha 3', value: KodOssz.PRAHA_3 },
  { label: 'Praha 4', value: KodOssz.PRAHA_4 },
  { label: 'Praha 5', value: KodOssz.PRAHA_5 },
  { label: 'Praha 6', value: KodOssz.PRAHA_6 },
  { label: 'Praha 7', value: KodOssz.PRAHA_7 },
  { label: 'Praha 8', value: KodOssz.PRAHA_8 },
  { label: 'Praha 9', value: KodOssz.PRAHA_9 },
  { label: 'Praha 5 Jihozápadní Město', value: KodOssz.PRAHA_5_JIHOZAPADNI_MESTO },
  { label: 'Praha 4 Modřany', value: KodOssz.PRAHA_4_MODRANY },
  { label: 'Praha 4 Jižní Město', value: KodOssz.PRAHA_4_JIZNI_MESTO },
  { label: 'Benešov', value: KodOssz.BENESOV },
  { label: 'Beroun', value: KodOssz.BEROUN },
  { label: 'Kladno', value: KodOssz.KLADNO },
  { label: 'Kolín', value: KodOssz.KOLIN },
  { label: 'Kutná Hora', value: KodOssz.KUTNA_HORA },
  { label: 'Mělník', value: KodOssz.MELNIK },
  { label: 'Mladá Boleslav', value: KodOssz.MLADA_BOLESLAV },
  { label: 'Nymburk', value: KodOssz.NYMBURK },
  { label: 'Praha Východ', value: KodOssz.PRAHA_VYCHOD },
  { label: 'Praha Západ', value: KodOssz.PRAHA_ZAPAD },
  { label: 'Příbram', value: KodOssz.PRIBRAM },
  { label: 'Rakovník', value: KodOssz.RAKOVNIK },
  { label: 'České Budějovice', value: KodOssz.CESKE_BUDEJOVICE },
  { label: 'Český Krumlov', value: KodOssz.CESKY_KRUMLOV },
  { label: 'Jindřichův Hradec', value: KodOssz.JINDRICHUV_HRADEC },
  { label: 'Pelhřimov', value: KodOssz.PELHRIMOV },
  { label: 'Písek', value: KodOssz.PISEK },
  { label: 'Prachatice', value: KodOssz.PRACHATICE },
  { label: 'Strakonice', value: KodOssz.STRAKONICE },
  { label: 'Tábor', value: KodOssz.TABOR },
  { label: 'Domažlice', value: KodOssz.DOMAZLICE },
  { label: 'Cheb', value: KodOssz.CHEB },
  { label: 'Karlovy Vary', value: KodOssz.KARLOVY_VARY },
  { label: 'Klatovy', value: KodOssz.KLATOVY },
  { label: 'Plzeň Město', value: KodOssz.PLZEN_MESTO },
  { label: 'Plzeň Jih', value: KodOssz.PLZEN_JIH },
  { label: 'Plzeň Sever', value: KodOssz.PLZEN_SEVER },
  { label: 'Rokycany', value: KodOssz.ROKYCANY },
  { label: 'Sokolov', value: KodOssz.SOKOLOV },
  { label: 'Tachov', value: KodOssz.TACHOV },
  { label: 'Česká Lípa', value: KodOssz.CESKA_LIPA },
  { label: 'Děčín', value: KodOssz.DECIN },
  { label: 'Chomutov', value: KodOssz.CHOMUTOV },
  { label: 'Jablonec nad Nisou', value: KodOssz.JABLONEC_NAD_NISOU },
  { label: 'Liberec', value: KodOssz.LIBEREC },
  { label: 'Litoměřice', value: KodOssz.LITOMERICE },
  { label: 'Louny', value: KodOssz.LOUNY },
  { label: 'Most', value: KodOssz.MOST },
  { label: 'Teplice', value: KodOssz.TEPLICE },
  { label: 'Ústí nad Labem', value: KodOssz.USTI_NAD_LABEM },
  { label: 'Havlíčkův Brod', value: KodOssz.HAVLICKUV_BROD },
  { label: 'Hradec Králové', value: KodOssz.HRADEC_KRALOVE },
  { label: 'Chrudim', value: KodOssz.CHRUDIM },
  { label: 'Jičín', value: KodOssz.JICIN },
  { label: 'Náchod', value: KodOssz.NACHOD },
  { label: 'Pardubice', value: KodOssz.PARDUBICE },
  { label: 'Rychnov nad Kněžnou', value: KodOssz.RYCHNOV_NAD_KNEZNOU },
  { label: 'Semily', value: KodOssz.SEMILY },
  { label: 'Svitavy', value: KodOssz.SVITAVY },
  { label: 'Trutnov', value: KodOssz.TRUTNOV },
  { label: 'Ústí nad Orlicí', value: KodOssz.USTI_NAD_ORLICI },
  { label: 'Blansko', value: KodOssz.BLANSKO },
  { label: 'Brno', value: KodOssz.BRNO },
  { label: 'Brno Venkov', value: KodOssz.BRNO_VENKOV },
  { label: 'Břeclav', value: KodOssz.BRECLAV },
  { label: 'Zlín', value: KodOssz.ZLIN },
  { label: 'Hodonín', value: KodOssz.HODONIN },
  { label: 'Jihlava', value: KodOssz.JIHLAVA },
  { label: 'Kroměříž', value: KodOssz.KROMERIZ },
  { label: 'Prostějov', value: KodOssz.PROSTEJOV },
  { label: 'Třebíč', value: KodOssz.TREBIC },
  { label: 'Uherské Hradiště', value: KodOssz.UHERSKE_HRADISTE },
  { label: 'Vyškov', value: KodOssz.VYSKOV },
  { label: 'Znojmo', value: KodOssz.ZNOJMO },
  { label: 'Žďár nad Sázavou', value: KodOssz.ZDAR_NAD_SAZAVOU },
  { label: 'Jeseník', value: KodOssz.JESENIK },
  { label: 'Bruntál', value: KodOssz.BRUNTAL },
  { label: 'Frýdek-Místek', value: KodOssz.FRYDEK_MISTEK },
  { label: 'Karviná', value: KodOssz.KARVINA },
  { label: 'Nový Jičín', value: KodOssz.NOVY_JICIN },
  { label: 'Olomouc', value: KodOssz.OLOMOUC },
  { label: 'Opava', value: KodOssz.OPAVA },
  { label: 'Ostrava', value: KodOssz.OSTRAVA },
  { label: 'Přerov', value: KodOssz.PREROV },
  { label: 'Šumperk', value: KodOssz.SUMPERK },
  { label: 'Vsetín', value: KodOssz.VSETIN },
];

export const TySelectOsszCode = <Fields extends object, IsMulti extends boolean, Group extends GroupBase<Option>>
(props: TySelectProps<Option, Fields, IsMulti, Group>) => (
  <TySelect
    {...props}
    options={osszCodeOptions}
  />
);
