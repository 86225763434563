export const CoinIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.44561 8.33213C2.44561 8.33213 7.95818 7.54622 10.104 3.22583L11.6257 4.59259L8.70951 8.53753L4.38316 10.3278L3.17683 9.93683"
      fill="#EBF69D"
    />
    <path
      d="M5.14439 3.06311C5.14439 3.06311 1.47569 6.0167 1.96109 7.17438C1.96109 7.17438 3.269 9.76344 6.94056 6.72239C10.6109 3.6813 9.87684 2.16406 9.87684 2.16406C9.87684 2.16406 7.20277 1.37357 5.14439 3.06311Z"
      fill="#EBF69D"
    />
    <path
      d="M2.38868 7.31394C2.39672 7.06858 2.52242 6.69212 2.82252 6.21923C3.31976 5.43423 4.14664 4.59925 5.14738 3.86845C7.42187 2.20789 9.19618 2.0472 9.47185 2.42953C9.74632 2.81182 9.04096 4.46425 6.76767 6.12486C5.76693 6.85565 4.7221 7.3868 3.82695 7.61888C3.04612 7.82187 2.56896 7.73692 2.44444 7.5626C2.40519 7.50903 2.38506 7.42447 2.38868 7.31394ZM10.5987 2.70723C10.6101 2.35863 10.525 2.05064 10.3416 1.79536C9.9022 1.18393 9.00686 1.01717 7.82103 1.32483C6.79938 1.59045 5.62595 2.18183 4.51771 2.99178C3.41071 3.80056 2.48632 4.74057 1.91792 5.63632C1.25793 6.67741 1.13647 7.58659 1.57466 8.19799C2.01285 8.80938 2.90822 8.97492 4.09526 8.6673C5.11687 8.4029 6.28914 7.81026 7.39734 7.00152C8.50558 6.19157 9.42872 5.25274 9.99717 4.35578C10.3811 3.75189 10.5828 3.19066 10.5987 2.70723Z"
      fill="#899E0E"
    />
    <path
      d="M11.9174 4.54395C11.9288 4.19536 11.8437 3.88736 11.6591 3.63083L10.341 1.79532C10.1669 1.5525 9.83097 1.49893 9.5913 1.67346C9.35039 1.84917 9.29708 2.18788 9.47121 2.4307L10.7894 4.26621C10.9151 4.44056 10.8462 4.92346 10.4113 5.60958C9.91403 6.39458 9.08715 7.22957 8.08642 7.96036C5.81313 9.62097 4.03761 9.78162 3.76194 9.39929L2.44383 7.56256C2.26967 7.32095 1.93381 7.26617 1.69411 7.44192C1.4544 7.61766 1.39989 7.95634 1.57405 8.19794L2.89216 10.0347C3.33039 10.6448 4.22572 10.8116 5.41276 10.504C6.43442 10.2384 7.60785 9.64698 8.71488 8.83699C9.82308 8.02825 10.7474 7.08946 11.3159 6.1925C11.6999 5.58739 11.9016 5.02738 11.9174 4.54395Z"
      fill="#899E0E"
    />
  </svg>
);

