import { components, OptionProps, GroupBase } from 'react-select';

const { Option } = components;
type CustomOptionProps<
  O,
  I extends boolean,
  G extends GroupBase<O>
> = OptionProps<O, I, G>;

export const CustomOption = <O, I extends boolean, G extends GroupBase<O>>(
  props: CustomOptionProps<O, I, G>
) => {
  const { label, className } = props;
  return (
    <Option {...props} className={className}>
      <span>{label}</span>
    </Option>
  );
};
