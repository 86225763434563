import classNames from 'classnames';
import {
  FieldProps,
  InputProps as InformedInputProps,
  useField,
} from 'informed';
import React, { ComponentProps, ComponentType } from 'react';
import { InputControls } from './InputControls';

type Opt = {
  min?: number | string;
  max?: number | string;
};
const getMinMax = (value: number | string, opt: Opt) => {
  const min = opt.min !== undefined ? Number(opt.min) : opt.min;
  const max = opt.max !== undefined ? Number(opt.max) : opt.max;
  let ret = value;
  if (Number.isNaN(+ret)) ret = min || 0;
  if (min !== undefined && min > Number(value)) ret = min;
  if (max !== undefined && max < Number(value)) ret = max;
  return ret;
};

type ElementProps = {
  InputControlsElement: ComponentType<
    Omit<ComponentProps<typeof InputControls>, 'IconButtonElement'>
  >;
};

type CustomProps = {
  showControls?: boolean;
  prefixElement: React.ReactNode;
} & InformedInputProps;

export type InputProps<Fields extends object, T> = FieldProps<
  CustomProps,
  T,
  Fields
>;

export const Input = <Fields extends object, T>({
  showControls,
  prefixElement,
  type = 'text',
  InputControlsElement,
  className,
  ...props
}: InputProps<Fields, T> & ElementProps) => {
  const { render, fieldState, userProps, fieldApi, ref } = useField<
    CustomProps,
    number | string
  >({ ...props, type });
  const {
    id,
    label,
    placeholder = ' ',
    disabled = false,
    hidden,
    ...rest
  } = userProps;
  const { showError, value, maskedValue } = fieldState;
  const { setValue, setTouched, setFocused } = fieldApi;

  const stepUp = () => {
    setValue(
      getMinMax(Number(value || 0) + Number(rest.step || 1), {
        min: rest.min,
        max: rest.max,
      })
    );
    setTouched(true);
  };

  const stepDown = () => {
    setValue(
      getMinMax(Number(value || 0) - Number(rest.step || 1), {
        min: rest.min,
        max: rest.max,
      })
    );
    setTouched(true);
  };

  const onInternalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let final = e.target.value;
    if (type === 'number' && e.target.value !== '') {
      final = getMinMax(e.target.value, {
        min: rest.min,
        max: rest.max,
      }) as string;
    }

    return setValue(final, e);
  };

  return render(
    <div className="flex items-center">
      {prefixElement}
      <input
        ref={ref}
        id={id}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        value={!maskedValue && maskedValue !== 0 ? '' : maskedValue}
        {...rest}
        onChange={onInternalChange}
        onBlur={(e) => {
          setTouched(true, e);
          setFocused(false, e);
        }}
        onFocus={(e) => {
          setFocused(true, e);
        }}
        aria-invalid={showError}
        aria-describedby={`${id}-error`}
        className={classNames('outline-none w-full', className)}
      />
      {rest.type === 'number' && showControls && (
        <InputControlsElement onPlus={stepUp} onMinus={stepDown} />
      )}
    </div>
  );
};
