import { EmptyImageIcon } from '../icons';
import { Paragraph } from '../text';

export const FileErrorIndicator = () => {
  return (
    <div className="flex flex-col items-center gap-2">
      <EmptyImageIcon className="fill-base-back-3 w-10" />
      <Paragraph
        variant="body1Rg"
        className="text-base-front-inverse text-nowrap"
      >
        Nepodařilo se načíst soubor
      </Paragraph>
    </div>
  );
};
