import { useSort } from '@tymbe/components/table';
import { IconButton } from '../button';
import { TySortIcon, TySortIconDown, TySortIconUp } from '../icons';
import { TyTableHead } from './TableHead';

export type TableSortHeaderProps = {
  children: React.ReactNode;
  field?: string;
  initSort?: Record<string, number>;
  onSort?: (field: string, activeSortOrder: number) => void;
  className?: string;
};

export const TyTableSortHeader = ({
  field,
  initSort,
  onSort,
  className,
  children,
}: TableSortHeaderProps) => {
  const { sortOrder, changeSort } = useSort({ field, initSort, onSort });

  if (!field) {
    return <TyTableHead className={className} children={children} />;
  }

  return (
    <TyTableHead className={className}>
      <IconButton onClick={changeSort} className="p-0 w-max">
        <div className="flex flex-row">
          {children}
          <div className="p-1" />
          {sortOrder === 1 && <TySortIconUp />}
          {sortOrder === -1 && <TySortIconDown />}
          {sortOrder === 0 && <TySortIcon />}
        </div>
      </IconButton>
    </TyTableHead>
  );
};
