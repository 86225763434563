import { TyRemoteFilesViewer } from '@tymbe/ty-components/files';
import { ComponentProps } from 'react';
import { getBlobFromBucket } from './blob.utils';

type FilesFromBucketViewerProps = Omit<
  ComponentProps<typeof TyRemoteFilesViewer>,
  'getBlob'
>;

export const FilesFromBucketViewer = (props: FilesFromBucketViewerProps) => {
  return <TyRemoteFilesViewer getBlob={getBlobFromBucket} {...props} />;
};
