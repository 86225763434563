export const CheckmarkIcon = ({ className = '' }) => (
  <svg
    viewBox="0 0 13 10"
    className={`${className}`}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.13132 7.88963L1.57517 5.31046C1.28529 5.01797 0.812789 5.01714 0.52188 5.3086C0.233196 5.59783 0.232369 6.06593 0.520029 6.35618L4.13132 10L12.4835 1.57259C12.7695 1.28402 12.7695 0.818885 12.4835 0.530316C12.194 0.238162 11.7218 0.238051 11.4321 0.530068L4.13132 7.88963Z" />
  </svg>
);
