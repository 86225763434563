import moment from 'moment';
import { CheckmarkIcon, CrossIcon } from '@tymbe/ty-components/icons';
import { ApplicationData } from '../../../../../types/TymbeApi';
import { getDocumentTypeTranslation } from '../../../../../utils/documents';
import { ApplicationDetailRow } from './ApplicationDetailRow';
import { ApplicationStateBadgePill } from '../../../../../components/BadgePills/ApplicationBadgePill';

type Props = {
  application: ApplicationData;
};

export const ApplicationInfo = ({ application }: Props) => {
  return (
    <>
      <ApplicationDetailRow label="Stav">
        <ApplicationStateBadgePill state={application.state!} />
      </ApplicationDetailRow>
      <ApplicationDetailRow
        label="Poslední změny"
        stringValue={moment(application?.updated_at).format(
          'DD. MM. YYYY HH:mm'
        )}
      />
      <ApplicationDetailRow
        label="Vytvořeno"
        stringValue={moment(application?.created_at).format(
          'DD. MM. YYYY HH:mm'
        )}
      />
      <ApplicationDetailRow
        label="Smlouva"
        stringValue={getDocumentTypeTranslation(
          application?.contract?.documentType?.type
        )}
      />
      <ApplicationDetailRow label="Pozvánka">
        {application?.invitation ? (
          <CheckmarkIcon className="fill-system-front-success-icon w-4" />
        ) : (
          <CrossIcon className="stroke-system-front-error-icon w-4" />
        )}
      </ApplicationDetailRow>
    </>
  );
};
