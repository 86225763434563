import { Table } from '@tymbe/components/table';
import classNames from 'classnames';
import { ComponentProps } from 'react';

export const TyTable = ({
  className,
  ...rest
}: ComponentProps<typeof Table>) => (
  <Table
    className={classNames(
      className,
      'border',
      'border-special-outline-divider',
      'bg-base-front-inverse'
    )}
    {...rest}
  />
);
