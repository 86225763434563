import { Paragraph } from '@tymbe/ty-components/text';

type ApplicationDetailRowProps = {
  label: string;
  stringValue?: string;
  children?: React.ReactNode;
};

export const ApplicationDetailRow = ({
  label,
  stringValue,
  children,
}: ApplicationDetailRowProps) => {
  return (
    <div className="flex justify-between py-1">
      <Paragraph variant="body2Rg" className="w-[150px]">
        {label}
      </Paragraph>
      {stringValue && <Paragraph variant="body2Md">{stringValue}</Paragraph>}
      {children}
    </div>
  );
};
