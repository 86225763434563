import moment from 'moment';
import { TyCard } from '@tymbe/ty-components/layout';
import { CoinIcon } from '@tymbe/ty-components/icons';
import { Paragraph } from '@tymbe/ty-components/text';
import { ShiftData } from '../../types/TymbeApi';
import { TyCompanyLogoFromBucket } from '../blob/CompanyLogoFromBucket';

type ShiftCardProps = {
  shift: ShiftData;
};

export const MobileShiftCard = ({ shift }: ShiftCardProps) => {
  const duration = moment(shift.end_time).diff(moment(shift.start_time), 'h');

  return (
    <div className="p-6 bg-base-back-1 border-b border-special-outline-divider">
      <TyCard className={`px-5 py-4 bg-base-back-inverse`} disabled>
        <div className="flex justify-between">
          <Paragraph
            variant="captionMd"
            className="text-base-front-3 capitalize"
          >
            {moment(shift.start_time).format('dd DD.MM.YYYY, HH:mm') +
              moment(shift.end_time).format('-HH:mm')}
          </Paragraph>
          <Paragraph variant="captionMd" className="text-base-front-3">
            {duration}h
          </Paragraph>
        </div>
        <div className="flex items-center py-3">
          <TyCompanyLogoFromBucket
            src={shift?.company?.logo}
            className="mr-3 w-10 h-10"
          />
          <div className="flex flex-col">
            <Paragraph variant="body2Sb" className="text-base-front-1">
              {shift?.name}
            </Paragraph>
            <Paragraph variant="captionMd" className="text-base-front-3">
              {shift?.company?.name}
            </Paragraph>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-row rounded-lg border border-base-back-1 py-2">
            <Paragraph
              variant="body2Sb"
              className="text-base-front-1 px-4 border-r border-base-back-1"
            >
              {Number(shift.payment_base)} Kč/h
            </Paragraph>
            <div className="flex px-4 items-center">
              <CoinIcon className="pr-0.5" />
              <Paragraph
                variant="captionMd"
                className="text-special-outline-primary-selected"
              >
                {Number(shift.credits)} Kr/h
              </Paragraph>
            </div>
          </div>
        </div>
      </TyCard>
    </div>
  );
};
