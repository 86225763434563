import React, { ComponentPropsWithoutRef } from 'react';

type IFrameViewerProps = ComponentPropsWithoutRef<'iframe'> & {
  spinner?: React.ReactNode;
  fileErrorIndicator?: React.ReactNode;
  isError?: boolean;
  isLoading?: boolean;
};

export const IFrameViewer = ({
  spinner,
  fileErrorIndicator,
  isError = false,
  isLoading = false,
  ...props
}: IFrameViewerProps) => {
  const [innerLoading, setInnerLoading] = React.useState(false);
  const [innerError, setInnerError] = React.useState(false);

  const showSpinner = isLoading || innerLoading;
  const showFileError = isError || innerError;

  return (
    <>
      {showFileError && fileErrorIndicator}
      {showSpinner && spinner}
      <iframe
        onLoadStart={() => setInnerLoading(true)}
        onError={() => setInnerError(true)}
        onLoad={() => setInnerLoading(false)}
        style={{ display: showSpinner || showFileError ? 'none' : undefined }}
        {...props}
      />
    </>
  );
};
