import classNames from 'classnames';
import Modal from 'react-modal';

import { CrossIcon } from '../icons';
import { textStyles } from '../style/text-styles';

type SideModalProps = {
  title?: string;
  children: React.ReactNode;
  className?: string;
} & Modal.Props;

export const SideModal = ({
  children,
  title,
  className = '',
  onRequestClose,
  isOpen,
  ...props
}: SideModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={300}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      contentLabel="My dialog"
      className={classNames(
        'fixed',
        'top-4',
        'right-4',
        'bottom-4',
        'max-h-[calc(100vh-2rem)]',
        'bg-base-front-inverse',
        'translate-1/2',
        'rounded-3xl',
        'drop-shadow-lg',
        'overflow-y-auto',
        className
      )}
      overlayClassName={{
        base: classNames(
          'bg-base-back-overlay-1 fixed inset-0 z-modal',
          'transition-all duration-500 ease-in-out',
          'opacity-0',
          'translate-x-28'
        ),
        afterOpen: classNames(
          isOpen ? 'opacity-100' : 'opacity-0',
          isOpen ? '!translate-x-0' : 'translate-x-28'
        ),
        beforeClose: 'opacity-0 translate-x-28',
      }}
      {...props}
    >
      <div
        className={classNames(
          textStyles.h4,
          'flex justify-between content-center bg-base-front-inverse p-6'
        )}
      >
        {title}
        <CrossIcon
          onClick={onRequestClose}
          className="cursor-pointer text-system-front-default-icon"
        />
      </div>
      {children}
    </Modal>
  );
};
