import { Button } from '@tymbe/components/button';
import { ComponentProps } from 'react';
import Spinner from '../indicators/Spinner';

export interface ButtonWithLoadingProps extends ComponentProps<typeof Button> {
  loading?: boolean;
  spinnerClassName?: string;
}

export const ButtonWithLoading = ({
  loading,
  disabled,
  children,
  spinnerClassName,
  ...props
}: ButtonWithLoadingProps) => (
  <Button {...props} disabled={loading || disabled}>
    <div className="flex items-center">
      {
        <Spinner
          show={loading}
          className={`${spinnerClassName} p-3 bg-buttons-back-disabled absolute top-0 left-0`}
        />
      }
      {children}
    </div>
  </Button>
);
