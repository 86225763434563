import { ComponentProps } from 'react';
import { H4, Paragraph } from '../text';
import { TyTableCell } from './TableCell';
import { TyTableRow } from './TableRow';
import { EmptyBoxImage } from '../illustrations';

type NoDataTableRowProps = {
  colSpan: number;
  title?: string;
  text?: string;
  visible?: boolean;
} & ComponentProps<typeof TyTableRow>;

export const NoDataTableRow = ({
  colSpan,
  children,
  title = 'Je to prázdné!',
  text = 'Jestli se zde něco nacházelo, tak je to pryč.',
  visible = false,
  ...rest
}: NoDataTableRowProps) => {
  if (!visible) return null;

  return (
    <TyTableRow {...rest}>
      <TyTableCell className="py-4" align="center" colSpan={colSpan}>
        <EmptyBoxImage />
        <H4 className="text-base-front-2">{title}</H4>
        <Paragraph variant="body2Rg" className="text-base-front-3">
          {text}
        </Paragraph>
        {children}
      </TyTableCell>
    </TyTableRow>
  );
};
