export const enum KodOssz {
  /** Nepoužívat pro e-podání */
  CSSZ_PRAHA_USTREDI = '101',
  /**
   * Nepoužívat pro tiskopisy žádostí o vystavení potvrzení o příslušnosti k právním předpisům
   * soc. zabezpečení: SZZVPP, SZZ_OSVC_VPP, ZOSVCP - použít kód 112
   */
  PRAHA_10 = '110',
  /**
   * Nepoužívat pro tiskopisy žádostí o vystavení potvrzení o příslušnosti k právním předpisům
   * soc. zabezpečení: SZZVPP, SZZ_OSVC_VPP, ZOSVCP - použít kód 114
   */
  PRAHA_1 = '111',
  PRAHA_2 = '112',
  /**
   * Nepoužívat pro tiskopisy žádostí o vystavení potvrzení o příslušnosti k právním předpisům
   * soc. zabezpečení: SZZVPP, SZZ_OSVC_VPP, ZOSVCP - použít kód 116
   */
  PRAHA_3 = '113',
  PRAHA_4 = '114',
  /**
   * Nepoužívat pro tiskopisy žádostí o vystavení potvrzení o příslušnosti k právním předpisům
   * soc. zabezpečení: SZZVPP, SZZ_OSVC_VPP, ZOSVCP - použít kód 118
   */
  PRAHA_5 = '115',
  PRAHA_6 = '116',
  /**
   * Nepoužívat pro tiskopisy žádostí o vystavení potvrzení o příslušnosti k právním předpisům
   * soc. zabezpečení: SZZVPP, SZZ_OSVC_VPP, ZOSVCP - použít kód 114
   */
  PRAHA_7 = '117',
  /**
   * do 31.12.2008 místní příslušnost velkých organizací (pokud se zaměstnavatelé nepřeregistrují na jiné
   * ÚP PSSZ, používají  tento kód)
   */
  PRAHA_8 = '118',
  /**
   * Nepoužívat pro tiskopisy žádostí o vystavení potvrzení o příslušnosti k právním předpisům
   * soc. zabezpečení: SZZVPP, SZZ_OSVC_VPP, ZOSVCP - použít kód 118
   */
  PRAHA_9 = '119',
  /**
   * Nepoužívat pro tiskopisy žádostí o vystavení potvrzení o příslušnosti k právním předpisům
   * soc. zabezpečení: SZZVPP, SZZ_OSVC_VPP, ZOSVCP - použít kód 118
   */
  PRAHA_5_JIHOZAPADNI_MESTO = '121',
  /**
   * Nepoužívat pro tiskopisy žádostí o vystavení potvrzení o příslušnosti k právním předpisům
   * soc. zabezpečení: SZZVPP, SZZ_OSVC_VPP, ZOSVCP - použít kód 112
   */
  PRAHA_4_MODRANY = '122',
  /**
   * Nepoužívat pro tiskopisy žádostí o vystavení potvrzení o příslušnosti k právním předpisům
   * soc. zabezpečení: SZZVPP, SZZ_OSVC_VPP, ZOSVCP - použít kód 114
   */
  PRAHA_4_JIZNI_MESTO = '123',
  BENESOV = '220',
  BEROUN = '221',
  KLADNO = '222',
  KOLIN = '223',
  KUTNA_HORA = '224',
  MELNIK = '225',
  MLADA_BOLESLAV = '226',
  NYMBURK = '227',
  PRAHA_VYCHOD = '228',
  PRAHA_ZAPAD = '229',
  PRIBRAM = '230',
  RAKOVNIK = '231',
  CESKE_BUDEJOVICE = '332',
  CESKY_KRUMLOV = '333',
  JINDRICHUV_HRADEC = '334',
  PELHRIMOV = '335',
  PISEK = '336',
  PRACHATICE = '337',
  STRAKONICE = '338',
  TABOR = '339',
  DOMAZLICE = '440',
  CHEB = '441',
  KARLOVY_VARY = '442',
  KLATOVY = '443',
  PLZEN_MESTO = '444',
  PLZEN_JIH = '445',
  PLZEN_SEVER = '446',
  ROKYCANY = '447',
  SOKOLOV = '448',
  TACHOV = '449',
  CESKA_LIPA = '550',
  DECIN = '551',
  CHOMUTOV = '552',
  JABLONEC_NAD_NISOU = '553',
  LIBEREC = '554',
  LITOMERICE = '555',
  LOUNY = '556',
  MOST = '557',
  TEPLICE = '558',
  USTI_NAD_LABEM = '559',
  HAVLICKUV_BROD = '660',
  HRADEC_KRALOVE = '661',
  CHRUDIM = '662',
  JICIN = '663',
  NACHOD = '664',
  PARDUBICE = '665',
  RYCHNOV_NAD_KNEZNOU = '666',
  SEMILY = '667',
  SVITAVY = '668',
  TRUTNOV = '669',
  USTI_NAD_ORLICI = '670',
  BLANSKO = '771',
  BRNO = '772',
  BRNO_VENKOV = '773',
  BRECLAV = '774',
  ZLIN = '775',
  HODONIN = '776',
  JIHLAVA = '777',
  KROMERIZ = '778',
  PROSTEJOV = '779',
  TREBIC = '780',
  UHERSKE_HRADISTE = '781',
  VYSKOV = '782',
  ZNOJMO = '783',
  ZDAR_NAD_SAZAVOU = '784',
  JESENIK = '884',
  BRUNTAL = '885',
  FRYDEK_MISTEK = '886',
  KARVINA = '887',
  NOVY_JICIN = '888',
  OLOMOUC = '889',
  OPAVA = '890',
  OSTRAVA = '891',
  PREROV = '892',
  SUMPERK = '893',
  VSETIN = '894',
}
