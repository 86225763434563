import { getType } from 'mime';

export const enum FileType {
  IMAGE = 'image',
  PDF = 'pdf',
  OTHER = 'other',
}

export const isImage = (file: Blob): boolean => {
  return file.type.startsWith(FileType.IMAGE);
};

export const getNaiveFileType = (name: string): FileType => {
  const type = getType(name);
  if (type === 'application/pdf') return FileType.PDF;
  if (type?.startsWith(FileType.IMAGE)) return FileType.IMAGE;
  return FileType.OTHER;
};
