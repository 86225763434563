import { Radio } from '@tymbe/components/inputs/radio';
import classNames from 'classnames';
import { ComponentProps } from 'react';
import { textStyles } from '../../../style/text-styles';
import { Label } from '../../../text/components';
import { TyRadio } from '../Radio';

type BadgePillVariant = 'default' | 'info' | 'success' | 'warning' | 'error';

const variantStyles: Record<BadgePillVariant, string> = {
  default: classNames(
    'bg-system-back-default',
    'text-system-back-default-significant',
    'has-[:checked]:bg-system-back-default-significant',
    'has-[:checked]:text-base-front-inverse'
  ),
  info: classNames(
    'bg-system-back-info',
    'text-system-back-info-significant',
    'has-[:checked]:bg-system-back-info-significant',
    'has-[:checked]:text-base-front-inverse'
  ),
  success: classNames(
    'bg-system-back-success',
    'text-system-back-success-significant',
    'has-[:checked]:bg-system-back-success-significant',
    'has-[:checked]:text-base-front-inverse'
  ),
  warning: classNames(
    'bg-system-back-warning',
    'text-system-back-warning-significant',
    'has-[:checked]:bg-system-back-warning-significant',
    'has-[:checked]:text-base-front-inverse'
  ),
  error: classNames(
    'bg-system-back-error',
    'text-system-back-error-significant',
    'has-[:checked]:bg-system-back-error-significant',
    'has-[:checked]:text-base-front-inverse'
  ),
};

export type BadgePillRadioProps = {
  variant?: BadgePillVariant;
} & ComponentProps<typeof Radio>;

export const BadgePillRadio = ({
  variant,
  className = '',
  ...props
}: BadgePillRadioProps) => {
  const style = variantStyles[variant ?? 'default'];

  return (
    <TyRadio
      className={classNames(
        className,
        'rounded-lg px-3 py-1.5 text-nowrap mx-2',
        '[&>input]:appearance-none',
        style
      )}
      required={false}
      {...props}
    ></TyRadio>
  );
};
