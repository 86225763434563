import { AttendanceResolution } from '@tymbe/schema/enums';
import { unexcusedAbsencesHeaders } from './components/headers/unexcusedAbsencesHeaders';
import { UnexcusedAbsences } from './components/UnexcusedAbsences';

export const unexcusedAbsencesQueryId = 'admin/unexcusedAbsences';

export const UnexcusedAbsencesTab = () => {
  return (
    <UnexcusedAbsences
      query={{
        $joinRelation: '[attendance, shift, person.personData]',
        'attendance.resolution': AttendanceResolution.UNEXCUSED_ABSENCE,
        'excuses.id[$null]': true,
      }}
      headers={unexcusedAbsencesHeaders}
      queryId={unexcusedAbsencesQueryId}
      dateColumnGetter={(application) => application?.attendance?.created_at}
    />
  );
};
