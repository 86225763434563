export const defaultPageSizeOptions = [
  {
    value: 10,
    label: '10 /strana',
  },
  {
    value: 20,
    label: '20 / strana',
  },
  {
    value: 50,
    label: '50 / strana',
  },
  {
    value: 500,
    label: '500 / strana',
  },
  {
    value: 1000,
    label: '1000 / strana',
  },
  {
    value: 5000,
    label: '5000 / strana',
  },
];

