export const unexcusedAbsencesHeaders = [
  {
    value: 'person.id',
    label: 'Tymber',
    sortable: false,
  },
  {
    value: 'shift.name',
    label: 'Směna',
    sortable: false,
  },
  {
    value: 'shift.start_time',
    label: 'Datum směny',
    sortable: true,
  },
  {
    value: 'attendance.created_at',
    label: 'Datum docházky',
    sortable: true,
  },
  {
    value: 'actions',
    label: 'Akce',
    sortable: false,
  },
];
