export const EmptyBoxImage = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M81.5 161.5V75L37 50.5V137L81.5 161.5Z" fill="#BAC0D9" />
      <path d="M81.5 161.5V75L163 63.5V150L81.5 161.5Z" fill="#DBDFEE" />
      <path d="M81.5 75L37 50.5L118.5 38.5L163 63.5L81.5 75Z" fill="white" />
      <rect x="118" y="68" width="16" height="28" fill="#BAC0D9" />
      <path
        d="M110.499 144.5C110.499 144.5 104.382 121.658 105.051 116.041C105.651 111.001 106.537 104.808 108.582 104.808C109.573 104.808 112.544 109.863 114.029 110.986C115.515 112.11 118.486 105.932 119.972 105.932C121.457 105.932 123.933 110.425 124.924 110.986C125.914 111.548 129.875 105.932 131.361 105.932C132.847 105.932 139.219 104.247 141.2 104.247H145.226C146.019 104.247 150.178 102.749 152.159 102C152.159 102 152.751 105.63 153.999 109C155.246 112.37 153.999 124 153.999 124C151.027 125.685 149.43 117.75 147.702 118.849C141.522 122.781 142.499 142 142.499 142C142.499 142 140.398 129.877 136.931 128.753C135.215 128.197 134.455 132.011 129.999 131C127.523 130.438 124.19 137.945 122.705 137.945C121.219 137.945 116.714 126.723 115.999 127.5C110.313 133.678 110.499 144.5 110.499 144.5Z"
        fill="white"
      />
      <path
        d="M110.145 131.964C108.945 131.52 106.078 120.127 105.078 115.872C104.577 110.878 106.578 104.774 108.644 104.774C109.645 104.774 112.646 109.769 114.147 110.878C115.647 111.988 118.648 105.884 120.149 105.884C121.65 105.884 124.151 110.323 125.151 110.878C126.151 111.433 130.153 105.884 131.654 105.884C133.154 105.884 139.591 104.22 141.592 104.22H145.659C146.459 104.22 150.661 102.74 152.662 102C152.662 102 152.903 105.884 153.662 109.214C154.422 112.543 153.662 117.537 153.662 117.537C152.662 117.907 150.161 118.647 148.16 118.647C145.659 118.647 143.399 124.751 142.158 125.306C140.917 125.86 137.415 125.306 136.155 125.306C134.895 125.306 132.088 130.3 129.587 129.745C127.086 129.19 123.585 131.964 122.084 131.964C120.584 131.964 117.148 126.415 116.147 126.415C115.147 126.415 111.646 132.519 110.145 131.964Z"
        fill="#BAC0D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M109 129.894V104.915C108.864 104.824 108.744 104.774 108.644 104.774C108.074 104.774 107.509 105.239 107 106.017V123.438C107.665 125.864 108.379 128.26 109 129.894ZM111 107.14V131.772C111.594 131.406 112.299 130.642 113 129.803V109.706C112.541 109.157 112.042 108.503 111.547 107.854C111.364 107.614 111.18 107.374 111 107.14ZM115 110.898V127.359C115.492 126.796 115.898 126.415 116.147 126.415C116.347 126.415 116.642 126.635 117 126.988V108.979C116.296 109.837 115.599 110.613 115 110.898ZM119 106.601V129.275C119.685 130.084 120.388 130.884 121 131.398V106.236C120.695 106.016 120.407 105.884 120.149 105.884C119.826 105.884 119.433 106.168 119 106.601ZM123 108.379V131.757C123.463 131.588 123.999 131.334 124.575 131.06C124.715 130.994 124.856 130.927 125 130.86V110.775C124.62 110.473 124.085 109.78 123.489 109.008C123.329 108.802 123.166 108.59 123 108.379ZM127 109.782V130.027C127.683 129.8 128.364 129.653 129 129.673V107.806C128.751 108.051 128.499 108.304 128.249 108.555C127.818 108.987 127.394 109.413 127 109.782ZM131 106.112V129.548C131.681 129.243 132.36 128.682 133 128.065V105.723C132.413 105.823 131.942 105.884 131.654 105.884C131.47 105.884 131.249 105.967 131 106.112ZM135 105.343V126.029C135.458 125.596 135.852 125.306 136.155 125.306C136.376 125.306 136.666 125.323 137 125.348V104.938C136.665 105.005 136.328 105.074 135.996 105.141C135.658 105.21 135.324 105.278 135 105.343ZM139 104.551V125.504C139.696 125.548 140.401 125.571 141 125.532V104.253C140.457 104.305 139.764 104.413 139 104.551ZM143 104.22V124.426C143.288 124.018 143.603 123.506 143.942 122.956C144.274 122.418 144.628 121.843 145 121.295V104.22H143ZM147 103.923V119.055C147.379 118.798 147.766 118.647 148.16 118.647C148.433 118.647 148.714 118.633 149 118.609V103.291C148.276 103.533 147.578 103.756 147 103.923ZM149.858 103H151V118.295C151.756 118.131 152.456 117.935 153 117.762V105.376C152.753 103.47 152.662 102 152.662 102C151.93 102.271 150.904 102.64 149.858 103Z"
        fill="#98A1C7"
      />
      <path
        d="M102.177 97.4359C101.37 100.595 103.521 111.538 104.697 116.615C104.697 113.419 105.302 107.026 107.721 107.026C110.746 107.026 109.738 116.615 113.266 114.923C116.795 113.231 117.803 113.795 119.819 113.795C121.835 113.795 124.356 120 125.364 120C126.372 120 129.901 118.872 132.925 116.051C135.949 113.231 135.949 114.359 140.99 112.103C146.031 109.846 151.072 106.462 152.584 103.077C153.794 100.369 152.08 83.8974 151.072 76C148.047 81.641 141.393 92.359 138.974 90.1026C136.554 87.8462 132.253 84.6496 130.405 83.3333C129.06 87.4701 125.566 95.518 122.34 94.6154C119.113 93.7128 118.466 88.7863 118.13 86.7179L111.25 97.4359C110.746 95.1795 109.334 90.5539 107.721 90.1026C105.705 89.5385 103.185 93.4872 102.177 97.4359Z"
        fill="url(#paint0_radial_13284_34714)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M118.788 36.9414L164.5 62.6222V151.303L81.2145 163.055L35.5 137.886V49.2047L118.788 36.9414ZM38.5 53.0382L80 75.8865V158.962L38.5 136.114V53.0382ZM83 159.773L161.5 148.697V65.2265L83 76.3032V159.773ZM81.7855 73.4449L158.397 62.6346L118.212 40.0586L41.6267 51.335L81.7855 73.4449Z"
        fill="#28367B"
      />
      <mask
        id="mask0_13284_34714"
        style={{ 'maskType': 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="45"
        y="40"
        width="114"
        height="33"
      >
        <path
          d="M85.2619 73L45 50.8493L118.738 40L159 62.6027L85.2619 73Z"
          fill="#899E0E"
        />
      </mask>
      <g mask="url(#mask0_13284_34714)">
        <rect
          x="70.3594"
          y="45.2109"
          width="11"
          height="65.6387"
          transform="rotate(-61.3545 70.3594 45.2109)"
          fill="#BAC0D9"
        />
      </g>
      <path
        d="M171.925 96.1022C173.036 97.1695 174.035 98.4334 175.267 99.3661C176.733 100.474 179.089 97.6449 177.666 96.459C176.484 95.4717 175.006 94.7878 173.713 93.945C172.547 93.189 170.941 95.1528 171.925 96.1022Z"
        fill="#B4D111"
      />
      <path
        d="M168.926 55.5754C170.439 54.9654 171.962 54.3595 173.482 53.7499C174.977 53.1564 176.262 53.0263 176.878 51.405C177.132 50.724 176.536 49.8754 175.872 49.6653C174.238 49.1624 173.208 50.1786 171.881 50.8998C170.457 51.6716 169.03 52.4537 167.603 53.2148C166.124 54.0046 167.414 56.1923 168.926 55.5754Z"
        fill="#28367B"
      />
      <path
        d="M182.47 76.3226C183.487 76.5668 184.457 76.9127 185.481 77.1009C186.497 77.2884 187.505 77.7181 188.525 77.8393C190.383 78.0633 190.704 75.0128 188.752 74.5528C187.712 74.3096 186.698 74.3388 185.656 74.1754C184.635 74.014 183.657 73.9803 182.645 73.8665C181.255 73.7106 181.009 75.9663 182.47 76.3226Z"
        fill="#525D94"
      />
      <defs>
        <radialGradient
          id="paint0_radial_13284_34714"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(127.5 97.4359) rotate(90) scale(22.5641 26.1538)"
        >
          <stop stop-color="#111D5B" />
          <stop offset="1" stop-color="#28367B" />
        </radialGradient>
      </defs>
    </svg>
  );
};
