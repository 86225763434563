import { PersonAccountStateEnum } from '@tymbe/schema/enums';
import { PersonAccountStateData } from '@tymbe/schema/person-account-state.interface';
import Tooltip from 'rc-tooltip';

import {
  AccountActivityIcon,
  AccountAdminIcon,
  AccountBanIcon,
  AccountCompanyIcon,
  AccountRegistrationUnfinishedIcon,
  AccountVerificationIcon,
  ExclamationCircleOutlined,
} from '../../components/icons';
import { alert, dangerColor, verified } from '../../utils/colors';

export interface AccountStateProps {
  accountState?: PersonAccountStateData;
}

const AccountState = ({ accountState = undefined }: AccountStateProps) => {
  switch (accountState?.account_state) {
    case PersonAccountStateEnum.ADMIN:
      return (
        <Tooltip placement="right" trigger={['hover']} overlay="Admin">
          <div><AccountAdminIcon iconcolor={verified} /></div>
        </Tooltip>
      );
    case PersonAccountStateEnum.COMPANY:
      return (
        <Tooltip placement="right" trigger={['hover']} overlay="Company Admin">
          <div><AccountCompanyIcon iconcolor={verified} /></div>
        </Tooltip>
      );
    case PersonAccountStateEnum.BAN:
      return (
        <Tooltip placement="right" trigger={['hover']} overlay="Ban">
          <div><AccountBanIcon iconcolor={dangerColor} /></div>
        </Tooltip>
      );
    case PersonAccountStateEnum.UNVERIFIED_ACCOUNT:
      return (
        <Tooltip placement="right" trigger={['hover']} overlay="Neověřený účet">
          <div><AccountVerificationIcon iconcolor={alert} /></div>
        </Tooltip>
      );
    case PersonAccountStateEnum.UNFINISHED_REGISTRATION:
      return (
        <Tooltip placement="right" trigger={['hover']} overlay="Nedokončená registrace">
          <div><AccountRegistrationUnfinishedIcon iconcolor={alert} /></div>
        </Tooltip>
      );
    case PersonAccountStateEnum.UNVERIFIED_PROOF_OF_IDENTITY:
      return (
        <Tooltip placement="right" trigger={['hover']} overlay="Neověřený doklad totožnosti">
          <div><AccountActivityIcon iconcolor={alert} /></div>
        </Tooltip>
      );
    case PersonAccountStateEnum.ACTIVE:
      return (
        <Tooltip placement="right" trigger={['hover']} overlay="Aktivní účet">
          <div><AccountActivityIcon iconcolor={verified} /></div>
        </Tooltip>
      );
    default:
      return (
        <Tooltip placement="right" trigger={['hover']} overlay="Error">
          <div><ExclamationCircleOutlined iconcolor={dangerColor} /></div>
        </Tooltip>
      );
  }
};

export default AccountState;
