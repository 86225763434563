import classNames from 'classnames';
import Spinner from '../indicators/Spinner';
import { BlobFileViewer } from '@tymbe/components/files';
import { ComponentProps } from 'react';
import { FileErrorIndicator } from './FileErrorIndicator';

type TyBlobFileViewerProps = Omit<
  ComponentProps<typeof BlobFileViewer>,
  'spinner' | 'fileErrorIndicator'
>;

export const TyBlobFileViewer = ({
  className = '',
  ...props
}: TyBlobFileViewerProps) => {
  return (
    <BlobFileViewer
      className={classNames(
        className,
        'flex',
        'items-center',
        'justify-center',
        'h-[calc(80vh)]',
        'max-w-[calc(60vw)]',
        'pointer-events-auto'
      )}
      iFrameClassName="rounded-3xl w-[calc(100vw-300px)] h-[calc(75vh)]"
      imgClassName="rounded-3xl max-h-[calc(75vh)]"
      spinner={<Spinner className="text-base-front-inverse w-20 h-20" />}
      fileErrorIndicator={<FileErrorIndicator />}
      {...props}
    />
  );
};
