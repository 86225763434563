import { useCallback, useEffect, useRef } from 'react';

type Timer = ReturnType<typeof setTimeout>;
type SomeFunction = (...args: any[]) => void;

export function useDebounce<Func extends SomeFunction>(
  func: Func,
  timeout = 1000
) {
  const timer = useRef<Timer>();

  useEffect(() => {
    return () => {
      if (!timer.current) return;
      clearTimeout(timer.current);
    };
  }, []);

  const debouncedFunction = useCallback(
    ((...args) => {
      const newTimer = setTimeout(() => {
        func(...args);
      }, timeout);
      clearTimeout(timer.current);
      timer.current = newTimer;
    }) as Func,
    [func, timeout]
  );

  return debouncedFunction;
}
