import { Paginated, Query } from '@feathersjs/feathers';
import { Form } from 'informed';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import DocumentInfo from './DocumentInfo';
import NewDocument from './NewDocument';
import feathersClient from '../../../apiClient';
import TyAsyncSelect, { DefaultAsyncSelectOption } from '../../../components/inputs/TyAsyncSelect';
import TyCheckbox from '../../../components/inputs/TyCheckbox';
import Card from '../../../components/Layout/Card';
import { TablePagination } from '../../../components/Table';
import defaultPageSizeOptions from '../../../components/Table/table.utils';
import factoryLoadOptions from '../../../components/TyAdvancedSearch/Libs/factoryLoadOptions';
import { Option } from '../../../components/TyAdvancedSearch/Libs/transform';
import useURLParamsHandler from '../../../hooks/UrlParamsHandler/useURLParamsHandler';
import { PersonDocumentData } from '../../../types/TymbeApi';

const UserDocumentsList = () => {
  const { id: personId } = useParams();
  const [paginationStart, setPaginationStart] = useState<number>(0);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(defaultPageSizeOptions[0].value);
  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();
  const [documentTypes, setDocumentTypes] = useState(allSearchParams.documentTypes || undefined);
  const [qry, setQry] = useState<Query>({
    'person_document.person_id': personId,
    $eager: '[documentType, personDocumentFile, company]',
    $joinRelation: '[documentType]',
    $sort: { updated_at: -1 },
    $skip: paginationStart,
    $limit: paginationPageSize,
    'documentType.name': { $in: documentTypes?.map((doc: DefaultAsyncSelectOption) => doc.value) },
  });
  const queryClient = useQueryClient();

  const [validValue, setValidValue] = useState(allSearchParams.onlyValidDocuments || false);

  const now = moment();

  const { data: personDocuments, isLoading } = useQuery(
    ['person-documents', qry],
    async () =>
      feathersClient.service('person-document').find({
        query: qry,
      }) as Promise<Paginated<PersonDocumentData>>,
    {
      enabled: !!personId,
      onSuccess: (data) => {
        data.data.forEach((doc: PersonDocumentData) => {
          queryClient.setQueryData(['person-document', doc.id], doc);
        });
      },
    },
  );

  const onPageChange = (pageStart: number, pageEnd: number) => {
    setPaginationStart(pageStart);
    setPaginationPageSize(pageEnd - pageStart);
  };

  useEffect(() => {
    const q = {
      'person_document.person_id': personId,
      $eager: '[documentType, personDocumentFile, company]',
      $joinRelation: '[documentType]',
      $sort: { updated_at: -1 },
      $skip: paginationStart,
      $limit: paginationPageSize,
      'documentType.name': { $in: documentTypes?.map((doc: DefaultAsyncSelectOption) => doc.value) },
    };

    if (validValue) {
      setQry(
        {
          ...q,
          'person_document.valid_to': { $gte: now.startOf('day').toISOString() },
          'person_document.valid_from': { $lte: now.endOf('day').toISOString() },
          'person_document.approved': true,
        },
      );
    } else {
      setQry(q);
    }
  }, [paginationPageSize, paginationStart, personId, documentTypes, validValue]);

  const loadDocumentOptions = factoryLoadOptions(
    'document-type',
    ({ search, options }) => {
      const query: Query = {
        'personDocument.person_id': personId,
        $joinRelation: '[personDocument]',
        $select: ['document_type.name'],
        $distinct: ['document_type.name'],
        'document_type.name': { $ilike: `%${search}%` },
        $skip: options.length,
      };
      return { query };
    },
    (v: { name: string }) => ({ label: `${v.name}`, value: v.name }),
  );

  const onValidDocumentsChange = (value: boolean) => {
    setValidValue(value);
    setUrlParamsHandler({ onlyValidDocuments: value, page: 1 });
  };

  return (
    <Card>
      <div className="flex justify-between content-center">
        <Form
          className="flex justify-end pb-3"
        >
          <TyAsyncSelect
            loadOptions={loadDocumentOptions}
            name="documents"
            label="Hledat typ dokumentu"
            isClearable
            isMulti
            initialValue={documentTypes}
            onChange={(e) => {
              setDocumentTypes(e.value as Option[]);
              setUrlParamsHandler({ documentTypes: e.value as Option[] });
            }}
          />
          <TyCheckbox
            className="pl-5 self-center"
            name="valid_documents"
            label="Pouze platné dokumenty"
            initialValue={validValue}
            onChange={(e) => onValidDocumentsChange(e.value)}
          />
        </Form>
        <NewDocument />
      </div>
      <div>
        {personDocuments?.data.map((document: PersonDocumentData) => (
          <div key={document.id} className="pb-3">
            <DocumentInfo document={document} />
          </div>
        ))}
      </div>
      {!isLoading && !personDocuments?.data.length && documentTypes?.length
        ? (
          <div className="text-center font-bold p-5">
            Nelze zobrazit Dohody ani jiné dokumenty, které nejsou podepsané mezi Vaší firmou a Tymberem.
          </div>
        ) : null}
      <TablePagination
        rowsCount={personDocuments?.total}
        onChangePage={onPageChange}
      />
    </Card>
  );
};

export default UserDocumentsList;
