import { RadioProps as InformedRadioProps } from 'informed';
import React, { ReactNode, useContext } from 'react';

import RadioGroupContext from './RadioGroupContext';

type ProvidedProps = 'id' | 'checked' | 'type';

type FullRadioProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  keyof InformedRadioProps | ProvidedProps
> &
  InformedRadioProps;

interface RadioProps extends Omit<FullRadioProps, 'label'> {
  label: ReactNode;
  checked?: boolean;
}

export const Radio = ({
  label,
  value,
  className = '',
  required = true,
  ...props
}: RadioProps) => {
  const radioGroupContext = useContext(RadioGroupContext);
  if (!radioGroupContext) return null;

  const { radioGroupApi, radioGroupState, id } = radioGroupContext;
  const { setValue, setTouched } = radioGroupApi;
  const { value: groupValue } = radioGroupState;

  return (
    <input
      {...props}
      id={`${id}-${value}`}
      value={value}
      className={className}
      checked={groupValue === value}
      onChange={() => null}
      onClick={(e) => {
        if (groupValue === value && !required) {
          setValue(undefined, e);
        } else {
          setValue(value, e);
        }
      }}
      onBlur={(e) => {
        setTouched(true, e);
      }}
      type="radio"
    />
  );
};
