import classNames from 'classnames';
import { IconButton } from '../button';
import { ChevronDownIcon } from '../icons';
import { Paragraph } from '../text';

type TyFileViewerControlsProps = {
  setSelectedFileIdx: (idx: number) => void;
  selectedFileIdx: number;
  fileAmount: number;
};

export const TyFileViewerControls = ({
  selectedFileIdx,
  setSelectedFileIdx,
  fileAmount,
}: TyFileViewerControlsProps) => {
  return (
    <div
      className={classNames(
        'flex',
        'justify-center',
        'items-center',
        'gap-4',
        'pointer-events-auto'
      )}
    >
      <IconButton
        onClick={() => {
          setSelectedFileIdx(Math.max(0, selectedFileIdx - 1));
        }}
        className="border-2 border-base-front-inverse p-2 rounded-lg"
      >
        <ChevronDownIcon className="rotate-90 text-base-front-inverse" />
      </IconButton>
      <Paragraph variant="body1Md" className="text-base-front-inverse">
        {selectedFileIdx + 1}/{fileAmount}
      </Paragraph>
      <IconButton
        className="border-2 border-base-front-inverse p-2 rounded-lg"
        onClick={() => {
          setSelectedFileIdx(Math.min(fileAmount - 1, selectedFileIdx + 1));
        }}
      >
        <ChevronDownIcon className="-rotate-90 text-base-front-inverse" />
      </IconButton>
    </div>
  );
};
