export const ArrowLeftIcon = ({ className = '' }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path d="M8.39042 5.8041L6.66375 7.53076C6.40375 7.79076 6.40375 8.21076 6.66375 8.47076L8.39042 10.1974C8.81042 10.6174 9.53042 10.3174 9.53042 9.7241V6.27076C9.53042 5.67743 8.81042 5.3841 8.39042 5.8041Z" />
  </svg>
);

