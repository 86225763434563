import { ImageViewer } from '@tymbe/components/files';
import { FileLoadingSpinner } from './FileLoadingSpinner';
import { ComponentProps } from 'react';
import { EmptyImageIcon } from '../icons';

export const TyImageViewer = ({
  spinner,
  fileErrorIndicator,
  ...props
}: ComponentProps<typeof ImageViewer>) => {
  return (
    <ImageViewer
      spinner={spinner ?? <FileLoadingSpinner />}
      fileErrorIndicator={fileErrorIndicator ?? <EmptyImageIcon className="text-base-back-3" />}
      {...props}
    />
  );
};
