import { InputControls } from '@tymbe/components/inputs/InputControls';
import { ComponentProps } from 'react';
import { IconButton } from '../button';

type TyInputControlsProps = Omit<
  ComponentProps<typeof InputControls>,
  'IconButtonElement'
>;

export const TyInputControls = ({
  className,
  ...props
}: TyInputControlsProps) => {
  return <InputControls IconButtonElement={IconButton} {...props} />;
};
