import { ApplicationExcuseData } from '@tymbe/schema/src/application-excuse.interface';
import moment from 'moment';
import { TyCard, TyLinkCard } from '@tymbe/ty-components/layout';
import { AbsenceState, Roles } from '@tymbe/schema/enums';
import { Divider } from '@tymbe/ty-components/indicators';
import { TyTooltip } from '@tymbe/ty-components/text/tooltip';
import classNames from 'classnames';
import { Paragraph } from '@tymbe/ty-components/text';
import { AbsenceStateBadgePill } from '../BadgePills/AbsenceBadgePill';
import Protect from '../../apiClient/Protect';
import { AbsenceCategoryDisplay } from '../../utils/enums';

type ApplicationExcuseProps = {
  excuse: ApplicationExcuseData;
  selected: boolean;
  disabledOnTap?: boolean;
};

const getLinkTo = (excuse: ApplicationExcuseData) => {
  const suffix = `?excuseId=${excuse.id}`;
  switch (excuse.state) {
    case AbsenceState.PROCESSING:
      return `/administration/absence/processing${suffix}`;
    default:
      return `/administration/absence/resolved${suffix}`;
  }
};

export const ApplicationExcuse = ({
  excuse,
  selected,
  disabledOnTap = false,
}: ApplicationExcuseProps) => {
  return (
    <div className="mt-4">
      <div className="flex justify-between mb-1">
        <Paragraph variant="body2Md">
          {moment(excuse.created_at).format('DD.MM.YYYY, HH:mm')}
        </Paragraph>
        <AbsenceStateBadgePill state={excuse.state} />
      </div>
      <Protect
        auth={[
          Roles.SUPER_ADMIN,
          Roles.TYMBE_ADMIN,
          Roles.ADMIN,
          Roles.TYMBE_COORDINATOR,
        ]}
        redirect={false}
      >
        <TyLinkCard
          className={classNames(
            'px-5 py-4',
            selected &&
              'bg-special-back-selected-1 border border-base-back-2-transparent'
          )}
          to={getLinkTo(excuse)}
          disabled={disabledOnTap}
        >
          <div className="flex items-center">
            <img src="/excuse_shrug.png" alt="image" className="pr-3" />
            <div className="flex flex-col min-w-0">
              <Paragraph variant="body2Rg">
                {AbsenceCategoryDisplay[excuse.category]}
              </Paragraph>
              <TyTooltip overlay={excuse.reason ?? '-'}>
                <Paragraph variant="captionRg" className="truncate">
                  {excuse.reason ?? '-'}
                </Paragraph>
              </TyTooltip>
            </div>
          </div>
          {excuse.state === AbsenceState.CLARIFICATION_NEEDED && (
            <div>
              <Divider />
              <div className="flex">
                <Paragraph variant="body2Rg" className="pr-4 text-nowrap">
                  {moment(excuse.reviewed_at).format('DD.MM.YYYY, HH:mm')}
                </Paragraph>
                <div className="flex flex-col">
                  <Paragraph variant="body2Rg">
                    Odeslána žádost o upřesnění
                  </Paragraph>
                  <Paragraph variant="captionRg">{excuse.admin_note}</Paragraph>
                </div>
              </div>
            </div>
          )}
        </TyLinkCard>
      </Protect>
    </div>
  );
};
