export const ArrowRightIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.60875 10.1945L9.33542 8.46787C9.59542 8.20787 9.59542 7.78787 9.33542 7.52787L7.60875 5.8012C7.18875 5.38787 6.46875 5.6812 6.46875 6.27453V9.7212C6.46875 10.3212 7.18875 10.6145 7.60875 10.1945Z" />
  </svg>
);

