import { SpinnerProps } from '@tymbe/components/indicators/spinner.types';

const Spinner = ({ show = true, className = '' }: SpinnerProps) => {
  return !show ? null : (
    <span
      className={`${className} inset-0 flex items-center justify-center rounded-3xl`}
    >
      <svg
        className="animate-spin w-full h-full max-w-min max-h-min text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="2"
          // This cuts off part of the circle
          strokeDasharray="60"
          strokeDashoffset="20"
        />
      </svg>
    </span>
  );
};

export default Spinner;
