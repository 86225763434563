import { TyCompanyLogo } from '@tymbe/ty-components/files';
import { ComponentProps } from 'react';
import { useBlobFromBucket } from './useBlob';

export const TyCompanyLogoFromBucket = ({
  src,
  ...props
}: ComponentProps<typeof TyCompanyLogo>) => {
  const { isError, isLoading, blob } = useBlobFromBucket(src);
  const url = blob ? URL.createObjectURL(blob) : undefined;

  return (
    <TyCompanyLogo
      src={url}
      isError={isError}
      isLoading={isLoading}
      {...props}
    />
  );
};
