import { ApplicationData } from '@tymbe/schema/application.interface';
import { useFieldState } from 'informed';
import moment from 'moment';
import { TyTableCell, TyTableRow } from '@tymbe/ty-components/table';
import { CompanyColumn } from './CompanyColumn';
import { PersonColumn } from './PersonColumn';
import { BadgePillRadio } from '@tymbe/ty-components/inputs/radio/badgePill';
import { AbsenceState } from '@tymbe/schema/enums';
import { TyTextArea } from '@tymbe/ty-components/inputs';
import classNames from 'classnames';
import { TyTooltip } from '@tymbe/ty-components/text/tooltip';
import { TimerIcon } from '@tymbe/ty-components/icons/round';
import { TyRadioGroup } from '@tymbe/ty-components/inputs/radio';

export type DateColumnGetterProps = (
  application: ApplicationData
) => string | undefined | null;

type Props = {
  application: ApplicationData;
  dateColumnGetter: DateColumnGetterProps;
};

const hoursThatTymberHasToReact = 72;

export const ApplicationRow = ({ application, dateColumnGetter }: Props) => {
  const absenceStateRowName = `applications[${application.id}].state`;
  const { value: pickedState } = useFieldState(absenceStateRowName);

  const date = dateColumnGetter(application);
  const dateColValue = date ? moment(date).format('DD.MM.YYYY, HH:mm') : '-';

  const showTimer =
    application.lastExcuse?.state === AbsenceState.CLARIFICATION_NEEDED &&
    moment
      .tz('Europe/Prague')
      .diff(moment(application.lastExcuse.reviewed_at), 'hours') >
      hoursThatTymberHasToReact;

  const cols = [
    <PersonColumn person={application.person} />,
    <CompanyColumn application={application} />,
    moment(application!.shift?.start_time).format('DD.MM.YYYY, HH:mm'),

    <div className="flex items-center gap-1">
      {dateColValue}
      {showTimer && (
        <TyTooltip overlay={'Déle než 72 hodin bez reakce'} placement="top">
          <TimerIcon className="text-system-front-error-icon" />
        </TyTooltip>
      )}
    </div>,
    <TyRadioGroup name={absenceStateRowName} className="flex">
      <BadgePillRadio
        label="Schválit bez omluvenky"
        value={AbsenceState.SYSTEM_APPROVED}
        variant="success"
      />
      <BadgePillRadio
        label="Zamítnout bez omluvenky"
        value={AbsenceState.SYSTEM_REJECTED}
        variant="error"
      />
    </TyRadioGroup>,
  ];

  const rowBg = pickedState ? 'bg-special-back-selected-1' : '';

  return (
    <>
      <TyTableRow className={rowBg} key={application.id}>
        {cols.map((col, idx) => (
          <TyTableCell
            key={idx}
            className={classNames(
              'border-x-0',
              'border-b-0',
              [0, 1].includes(idx) ? '!py-0 !px-0' : ''
            )}
          >
            {col}
          </TyTableCell>
        ))}
      </TyTableRow>

      {pickedState && (
        <TyTableRow className={`${rowBg}`}>
          {/* Span input through last 3 columns */}
          <TyTableCell
            colSpan={cols.length - 3}
            className="border-x-0 border-t-0"
          />
          <TyTableCell colSpan={3} className="border-x-0 border-t-0">
            <TyTextArea
              name={`applications[${application.id}].admin_note`}
              placeholder="Poznámka pro Tymbera (nepovinné)"
            />
          </TyTableCell>
        </TyTableRow>
      )}
    </>
  );
};
