import { ComponentProps } from 'react';
import { CrossIcon } from '../icons';
import { IconButton } from './IconButton';

export const CloseButton = (props: ComponentProps<typeof IconButton>) => {
  return (
    <IconButton {...props}>
      <CrossIcon className='text-base-front-inverse'/>
    </IconButton>
  );
};
