import { BadgePill } from '@tymbe/ty-components/indicators';
import { getPaginatedResponse } from '../../apiClient/utils';

type Props = {
  data: unknown;
};

export const PaginatedTotalIndicator = ({ data }: Props) => {
  const amount = getPaginatedResponse(data).total;

  if (!amount) return null;

  return <BadgePill variant="warning">{amount}</BadgePill>;
};
