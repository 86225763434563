import Tooltip from 'rc-tooltip';
import { TooltipProps } from 'rc-tooltip/lib/Tooltip';
import './tooltip.css';

type TyTooltipProps = {
  placement?: string;
  className?: string;
  children: React.ReactElement;
} & TooltipProps;

export const TyTooltip = ({
  children,
  className,
  placement = 'topLeft',
  ...props
}: TyTooltipProps) => {
  return (
    <div className={className}>
      <Tooltip placement={placement} {...props}>
        <div>{children}</div>
      </Tooltip>
    </div>
  );
};
