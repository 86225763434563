import classNames from 'classnames';
import { TyFileViewerControls } from './FileViewerControls';
import { FileIcon } from './FileIcon';
import { CloseButton } from '../button/CloseButton';
import { useState } from 'react';
import { getNaiveFileType } from '@tymbe/components/files/utils';
import Modal from 'react-modal';
import { Paragraph } from '../text';
import { useQuery } from 'react-query';
import { TyBlobFileViewer } from './BlobFileViewer';

export type TyRemoteFilesViewer = {
  files: string[];
  max?: number;
  getBlob: (src: string) => Promise<Blob>;
};

export const TyRemoteFilesViewer = ({
  files,
  max,
  getBlob,
}: TyRemoteFilesViewer) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFileIdx, setSelectedFileIdx] = useState(0);
  const maxReached = (max ?? files.length) < files.length;

  if (!files.length) {
    return null;
  }

  const {
    data: blob,
    isLoading,
    isError,
  } = useQuery(['getBlob', files[selectedFileIdx]], () =>
    getBlob(files[selectedFileIdx])
  );

  return (
    <>
      <div className="flex gap-2">
        {files.slice(0, max ?? files.length).map((file, idx) => (
          <FileIcon
            type={getNaiveFileType(file)}
            onClick={() => {
              setSelectedFileIdx(idx);
              setIsOpen(true);
            }}
            fileName={file}
            key={`fileIcon-${file}`}
          />
        ))}
        {maxReached && <Paragraph variant="captionRg">...</Paragraph>}
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        overlayClassName={{
          base: classNames(
            'bg-base-back-overlay-3 fixed inset-0 z-modal',
            'transition-all duration-300 ease-in-out',
            'opacity-0'
          ),
          afterOpen: 'opacity-100',
          beforeClose: '!opacity-0',
        }}
        className={classNames(
          'flex',
          'flex-col',
          'items-center',
          'absolute',
          'left-[50%]',
          'top-[50%]',
          'transform',
          '-translate-y-1/2',
          '-translate-x-1/2',
          'w-screen',
          'h-screen',
          // This enables user to close the modal by tapping on the "overlay" (modal bcs of w-screen)
          // pointer-events-auto is needed on every interactive element inside it though
          'pointer-events-none'
        )}
      >
        <CloseButton
          onClick={() => setIsOpen(false)}
          // align to end
          className="pointer-events-auto absolute top-8 right-8"
        />
        <TyBlobFileViewer blob={blob} isLoading={isLoading} isError={isError} />
        <TyFileViewerControls
          setSelectedFileIdx={setSelectedFileIdx}
          selectedFileIdx={selectedFileIdx}
          fileAmount={files.length}
        />
      </Modal>
    </>
  );
};
