import { Select } from '@tymbe/components/inputs/Select';
import { TyInputError } from './InputError';
import classNames from 'classnames';
import { ChevronDownIcon } from '../icons';
import { Label } from '../text/components';
import { ComponentProps } from 'react';
import { TyCustomOption } from './CustomOption';
import { GroupBase } from 'react-select';
import { textStyles } from '../style/text-styles';

type TySelectProps<
  Fields extends object,
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> = Omit<
  ComponentProps<typeof Select<Fields, Option, IsMulti, Group>>,
  'CustomOptionElement'
>;

export const TySelect = <
  Fields extends object,
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: TySelectProps<Fields, Option, IsMulti, Group>
) => {
  return (
    <div className={props.className}>
      {props.label && <Label>{props.label}</Label>}
      <Select
        CustomOptionElement={TyCustomOption}
        dropDownIndicator={<ChevronDownIcon />}
        classNames={{
          control: ({ isDisabled }) =>
            classNames(
              textStyles.pBody2Rg,
              isDisabled && 'text-base-front-disabled',
              'border border-special-outline-divider rounded-lg px-2 py-1.5 min-w-full'
            ),
          menu: () => 'bg-base-front-inverse rounded-lg shadow-s',
          menuList: () => '[&::-webkit-scrollbar]:hidden rounded-lg',
          option: ({ isFocused }) =>
            classNames(isFocused ? 'bg-base-back-2' : 'bg-base-front-inverse'),
        }}
        {...props}
      />
      <TyInputError name={props.name} />
    </div>
  );
};
