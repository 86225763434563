import { ApplicationState } from '@tymbe/schema/enums';
import { canceledLateHeaders } from './components/headers/canceledLateHeaders';
import { UnexcusedAbsences } from './components/UnexcusedAbsences';

export const canceledLateQueryId = 'admin/canceledLate';

export const CanceledLateTab = () => {
  return (
    <UnexcusedAbsences
      query={{
        'application.state': ApplicationState.CANCELED_LATE,
        'excuses.id[$null]': true,
      }}
      headers={canceledLateHeaders}
      queryId={canceledLateQueryId}
      dateColumnGetter={(application) => application.updated_at}
    />
  );
};
