export const textStyles = {
  h1: 'text-7xl tracking-h1 font-geistSb',
  h2: 'text-4xl tracking-h2 font-geistSb',
  h3: 'text-2xl tracking-h3 font-geistSb',
  h4: 'text-lg tracking-h4 font-geistSb',
  pBody1Sb: 'text-base font-geistSb',
  pBody1Md: 'text-base font-geistMd',
  pBody1Rg: 'text-base font-geistRg',
  pBody2Sb: 'text-sm font-geistSb',
  pBody2Md: 'text-sm font-geistMd',
  pBody2Rg: 'text-sm font-geistRg',
  pCaptionSb: 'text-xs font-geistSb',
  pCaptionMd: 'text-xs font-geistMd',
  pCaptionRg: 'text-xs font-geistRg',
  cButtonMd: 'text-sm font-geistSb',
  cButtonSm: 'text-xs font-geistSb',
  cLabel: 'text-xs font-geistSb',
  cHelperText: 'text-2xs font-geistRg',
};
