import { ShiftStateData } from '@tymbe/schema/shift-state.interface';
import moment from 'moment';
import { useMutation } from 'react-query';

import TableColumnWithControls from './TableColumnWithControls';
import feathersClient from '../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';

interface OrdersCountColumnProps {
  showControls: boolean,
  data: ShiftStateData,
  onClick: () => void,
}

const OrdersCountColumn = ({
  showControls,
  data,
  onClick,
}: OrdersCountColumnProps) => {
  const { mutateAsync: removeManShift } = useMutation(
    ['remove-manShift'],
    async (manShiftId: number) =>
      feathersClient.service('man-shift').remove(manShiftId),
    {
      onError: () => {
        ErrorAlert('Nepodařilo se odebrat volné místo ze směny');
      },
      onSuccess: () => {
        SuccessAlert('Volné místo bylo odebráno');
      },
    },
  );

  const { mutateAsync: addOpenManShift } = useMutation(
    ['create-open-manShift'],
    async (shiftData: ShiftStateData) => feathersClient.service('man-shift').create({ shift_id: shiftData.id }),
    {
      onError: () => {
        ErrorAlert('Nepodařilo se přidat místo ke směně');
      },
      onSuccess: (_, { start_time }) => {
        const isShiftBetweenNowAndPlusTwoDays = moment(start_time).tz('europe/prague').isBetween(moment(), moment().add(2, 'days'));
        if (isShiftBetweenNowAndPlusTwoDays) {
          SuccessAlert({
            content: 'Objednávka s nástupem do 48 hodin - každý si občas rád zkusí život na hraně, takže posíláme Váš adrenalin do našeho týmu a použijeme všechny dostupné nástroje k obsazení Vašich směn. Když si budete chtít zkusit i pohodovou objednávku, takových 5 dní je na dobré obsazení tak akorát.',
            duration: 8,
            style: { maxWidth: 500, margin: 'auto' },
          });
          return;
        }
        SuccessAlert('Do objednávky bylo přidáno nové místo');
      },
    },
  );

  const onPlusClick = async (shiftData: ShiftStateData) => {
    await addOpenManShift(shiftData);
    onClick();
  };

  const onMinusClick = async ({ manShift }: ShiftStateData) => {
    const manShiftToRemove = manShift?.find((ms) => ms.application_id === null && ms.deleted_at === null);
    if (!manShiftToRemove) {
      ErrorAlert('Z této objednávky již nelze odebrat místa');
      return;
    }
    await removeManShift(manShiftToRemove.id);
    onClick();
  };

  return (
    <TableColumnWithControls
      showControls={showControls}
      onPlusClick={() => onPlusClick(data)}
      onMinusClick={() => onMinusClick(data)}
    >
      {data.orders_count}
    </TableColumnWithControls>
  );
};

export default OrdersCountColumn;
