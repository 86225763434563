import {
  useRef,
  cloneElement,
  useEffect,
  useCallback,
  ReactElement,
} from 'react';

type NavTabsProps = {
  children: ReactElement | ReactElement[];
  containerClassName?: string;
  tabIndicatorClassName?: string;
};

export const NavTabs = ({
  children,
  containerClassName,
  tabIndicatorClassName,
}: NavTabsProps) => {
  const tabIndicatorRef = useRef<HTMLDivElement>(null);
  const tabWrapperRef = useRef<HTMLDivElement>(null);

  const setIndicator = useCallback((left: number, width: number) => {
    if (!tabIndicatorRef.current) return;

    tabIndicatorRef.current.style.left = `${left}px`;
    tabIndicatorRef.current.style.width = `${width}px`;
  }, []);

  const tabs = [children].flat().map((child) =>
    cloneElement(child, {
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        child.props.onClick?.(e);
        if (!tabIndicatorRef.current) return;
        setIndicator(e.currentTarget.offsetLeft, e.currentTarget.clientWidth);
      },
      key: child.key,
    })
  );

  useEffect(() => {
    if (!tabWrapperRef.current || !tabIndicatorRef.current) return;

    const active =
      tabWrapperRef.current.querySelector<HTMLElement>(':scope > *.active');

    if (!active) return;

    setIndicator(active.offsetLeft, active.clientWidth);
  }, [children, setIndicator]);

  return (
    <div className={containerClassName} ref={tabWrapperRef}>
      {tabs}
      <div className={tabIndicatorClassName} ref={tabIndicatorRef} />
    </div>
  );
};
