import { ReactNode, useCallback, useEffect } from 'react';
import { useDebounce } from '@tymbe/utils/hooks';

export type SearchProps = {
  onSearch?: (value?: string) => void;
  debounceTimeout?: number;
  value?: string | null;
  children: ReactNode;
};

export const Search = ({
  onSearch,
  debounceTimeout = 500,
  value,
  children,
}: SearchProps) => {
  const handleSearch = useCallback(
    (newValue?: string) => {
      const trimmedValue = newValue?.trim();
      onSearch?.(trimmedValue);
    },
    [onSearch]
  );

  const debounceFetcher = useDebounce(handleSearch, debounceTimeout);

  useEffect(() => {
    debounceFetcher(value ?? undefined);
  }, [value]);

  return <>{children}</>;
};
