import { Input, Search } from '@tymbe/components/inputs';
import { useFieldApi } from 'informed';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SearchIcon } from '../icons';
import { TyInput } from './Input';

type TySearchBoxProps<Fields extends object> = Omit<
  ComponentProps<typeof Input<Fields, string>>,
  'InputControlsElement' | 'prefixElement'
> & {
  onSearch?: (value?: string) => void;
  debounceTimeout?: number;
};

export const TySearchBox = <Fields extends object>({
  className = '',
  name,
  onSearch,
  debounceTimeout,
  ...props
}: TySearchBoxProps<Fields>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchedValue = searchParams.get('search');
  const [search, setSearch] = useState<string | null>(searchedValue);
  const { setValue: setInputValue, getValue: getInputValue } =
    useFieldApi<string>(name);

  useEffect(() => {
    // Remove searched value from input when it's not relevant anymore
    if (searchedValue != getInputValue()) {
      setInputValue(searchedValue ?? '');
    }
  }, [searchedValue]);

  const onSearchInner = useCallback(
    (value?: string) => {
      if (value) {
        setSearchParams({ search: value! });
      } else {
        searchParams.delete('search');
        setSearchParams(searchParams);
      }
      onSearch?.(value);
    },
    [onSearch, searchParams, setSearchParams]
  );

  return (
    <Search
      value={search}
      onSearch={onSearchInner}
      debounceTimeout={debounceTimeout}
    >
      <TyInput<Fields, string>
        prefixElement={<SearchIcon className="mr-2 text-base-front-3" />}
        className="p-2"
        type="text"
        onChange={({ value }) => setSearch(value?.toString())}
        name={name}
        defaultValue={searchedValue ?? undefined}
        {...props}
      />
    </Search>
  );
};
