import { useQueryClient } from 'react-query';

import ApplicationDetails from './ApplicationDetails';
import AttendanceDetails from './AttendanceDetails';
import AcceptInvitationButton from './Buttons/AcceptInvitationButton';
import CancelInvitationButton from './Buttons/CancelInvitationButton';
import CreateLiability from './Buttons/CreateLiability';
import DeleteAttendance from './Buttons/DeleteAttendance';
import EditApplication from './Buttons/EditApplication';
import EditApplicationState from './Buttons/EditApplicationState';
import EditAttendanceResolution from './Buttons/EditAttendanceResolution';
import EditJobEvaluation from './Buttons/EditJobEvaluation';
import RejectInvitationButton from './Buttons/RejectInvitationButton';
import ResendInvitationButton from './Buttons/ResendInvitationButton';
import PersonLiabilityDetails from './PersonLiabilityDetails';
import ShiftInfoDetailComponent from './ShiftInfoDetailComponent';
import ShiftState from './ShiftUtils/ShiftStateEnum';
import Protect from '../../../apiClient/Protect';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import {
  ApplicationData,
  ApplicationState,
  ShiftData,
} from '../../../types/TymbeApi';
import { getRolesWhichCanDeleteAttendance } from '../../../utils/attendance';
import {
  ApplicationStateDisplay,
  AttendanceResolutionDisplay,
  Roles,
} from '../../../utils/enums';
import AbsenceDetails from './AbsencesDetail';

export interface ShiftInfoDetailProps {
  application: ApplicationData & { shift: ShiftData };
  state: ShiftState;
}

const ShiftInfoDetail = ({ application, state }: ShiftInfoDetailProps) => {
  const qClient = useQueryClient();

  const acceptedShiftHappend = (app: ApplicationData) =>
    app?.state === ApplicationState.CONFIRMED &&
    app.shift &&
    new Date(app.shift.end_time).getTime() < Date.now();

  const successHandler = (successText: string) => {
    qClient.invalidateQueries(['application', application.id]);
    SuccessAlert(successText);
  };
  const errorHandler = (err: string, errorData?: any) => {
    qClient.invalidateQueries(['application', { id: application.id }]);
    if (errorData?.message === 'User missing required documents') {
      ErrorAlert(
        'Uživatel nemá nahrány nebo podepsány všechny potřebné dokumenty'
      );
      return;
    }
    ErrorAlert(err);
  };
  const createdByName = `${application.attendance?.confirmedBy?.first_name.concat(
    ' ',
    application.attendance?.confirmedBy?.last_name
  )}`;
  return (
    <div className="border-t">
      {application?.invitation ? (
        <ShiftInfoDetailComponent
          label="Pozvánka na směnu odeslána"
          lastUpdate={application.updated_at}
          createdAt={application.created_at}
          detailComponent={<ApplicationDetails application={application} />}
        >
          {state !== ShiftState.INVITATION ? null : (
            <div className="flex-col">
              <Protect
                auth={[Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN]}
                redirect={false}
              >
                <AcceptInvitationButton
                  applicationId={application.id}
                  onSuccess={() => successHandler('Směna přijata')}
                  onError={(error) =>
                    errorHandler('V procesu přijetí směny došlo k chybě', error)
                  }
                />
              </Protect>
              <Protect
                auth={[Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN, Roles.ADMIN]}
                redirect={false}
              >
                <RejectInvitationButton
                  applicationId={application.id}
                  onSuccess={() => successHandler('Směna odmítnuta')}
                  onError={(error) =>
                    errorHandler(
                      'V procesu odmítnutí směny došlo k chybě',
                      error
                    )
                  }
                />
              </Protect>
              <Protect
                auth={[
                  Roles.SUPER_ADMIN,
                  Roles.TYMBE_ADMIN,
                  Roles.ADMIN,
                  Roles.TYMBE_COORDINATOR,
                ]}
                redirect={false}
              >
                <CancelInvitationButton
                  applicationId={application.id}
                  onSuccess={() => successHandler('Pozvánka zrušena')}
                  onError={() =>
                    errorHandler('V procesu zrušení pozvánky došlo k chybě')
                  }
                />
              </Protect>
              <Protect
                auth={[
                  Roles.SUPER_ADMIN,
                  Roles.TYMBE_ADMIN,
                  Roles.TYMBE_COORDINATOR,
                ]}
                redirect={false}
              >
                <EditApplication
                  application={application}
                  onSuccess={() => successHandler('Cena přihlášky upravena')}
                />
              </Protect>
            </div>
          )}
        </ShiftInfoDetailComponent>
      ) : null}
      {application?.state ? (
        <ShiftInfoDetailComponent
          label={ApplicationStateDisplay[application?.state]}
          lastUpdate={application.updated_at}
          createdAt={application.created_at}
          detailComponent={
            application.invitation ? null : (
              <ApplicationDetails application={application} />
            )
          }
        >
          <>
            {state !== ShiftState.APPLICATION_CONFIRMED ? null : (
              <>
                <Protect
                  auth={[Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN]}
                  redirect={false}
                >
                  <EditApplication
                    application={application}
                    onSuccess={() => successHandler('Cena přihlášky upravena')}
                  />
                </Protect>
                <Protect
                  auth={[
                    Roles.SUPER_ADMIN,
                    Roles.TYMBE_ADMIN,
                    Roles.TYMBE_COORDINATOR,
                  ]}
                  redirect={false}
                >
                  <EditApplicationState
                    applicationId={application.id}
                    manShiftId={application.man_shift_id}
                    onSuccess={() => successHandler('Přihláška upravena')}
                  />
                </Protect>
              </>
            )}

            {application.state &&
            [
              ApplicationState.SYSTEM_CANCELED,
              ApplicationState.CANCELED_EARLY,
              ApplicationState.CANCELED_LATE,
              ApplicationState.REJECTED,
            ].includes(application.state) ? (
              <ResendInvitationButton application={application} />
            ) : null}
          </>
        </ShiftInfoDetailComponent>
      ) : null}

      {acceptedShiftHappend(application) && application.attendance ? (
        <ShiftInfoDetailComponent
          label={AttendanceResolutionDisplay[application.attendance.resolution]}
          lastUpdate={application?.attendance?.updated_at}
          createdAt={application?.attendance?.created_at}
          createdBy={createdByName}
          detailComponent={
            <AttendanceDetails attendance={application.attendance} />
          }
        >
          <>
            {[ShiftState.ATTENDANCE_OK, ShiftState.SURVEY].includes(state) ? (
              <>
                {application?.attendance?.personLiability ? null : (
                  <Protect
                    auth={[
                      Roles.SUPER_ADMIN,
                      Roles.TYMBE_ADMIN,
                      Roles.COMPANY,
                      Roles.BRANCHOFFICE_MANAGER,
                    ]}
                    redirect={false}
                  >
                    <CreateLiability
                      attendanceId={application.attendance.id}
                      personId={application.person_id}
                      onSuccess={() => successHandler('Manko zadáno')}
                    />
                  </Protect>
                )}
                <Protect
                  auth={getRolesWhichCanDeleteAttendance({
                    ...application.attendance,
                    application,
                  })}
                  redirect={false}
                >
                  <DeleteAttendance
                    attendanceId={application.attendance.id}
                    onSuccess={() => successHandler('Docházka smazána')}
                    onError={() =>
                      errorHandler('Docházku se nepodařilo smazat')
                    }
                  />
                </Protect>
                <Protect auth={[Roles.SUPER_ADMIN]} redirect={false}>
                  <EditAttendanceResolution
                    attendanceId={application.attendance.id}
                    onSuccess={() => successHandler('Stav docházky upraven')}
                  />
                </Protect>
                <Protect auth={[Roles.SUPER_ADMIN]} redirect={false}>
                  <EditJobEvaluation
                    attendance={application.attendance}
                    onSuccess={() => successHandler('Hodnocení upraveno')}
                  />
                </Protect>
              </>
            ) : null}

            {[
              ShiftState.ATTENDANCE_EXCUSED_ABSENCE,
              ShiftState.ATTENDANCE_ADMIN_EXCUSED_ABSENCE,
              ShiftState.ATTENDANCE_BREACH_OF_DISCIPLINE,
              ShiftState.ATTENDANCE_CANCEL,
              ShiftState.ATTENDANCE_UNEXCUSED_ABSENCE,
            ].includes(state) ? (
              <>
                {application?.attendance?.personLiability ? null : (
                  <CreateLiability
                    attendanceId={application.attendance.id}
                    personId={application.person_id}
                    onSuccess={() => successHandler('Manko zadáno')}
                  />
                )}

                <Protect
                  auth={getRolesWhichCanDeleteAttendance({
                    ...application.attendance,
                    application,
                  })}
                  redirect={false}
                >
                  <DeleteAttendance
                    attendanceId={application.attendance.id}
                    onSuccess={() => successHandler('Docházka smazána')}
                    onError={() =>
                      errorHandler('Docházku se nepodařilo smazat')
                    }
                  />
                </Protect>

                <EditAttendanceResolution
                  attendanceId={application.attendance.id}
                  onSuccess={() => successHandler('Stav docházky upraven')}
                />
                <EditJobEvaluation
                  attendance={application.attendance}
                  onSuccess={() => successHandler('Hodnocení upraveno')}
                />
              </>
            ) : null}

            {state === ShiftState.SURVEY && (
              <Protect auth={[Roles.SUPER_ADMIN]} redirect={false}>
                <DeleteAttendance
                  deleteSurveyAnswers
                  attendanceId={application.attendance.id}
                  onSuccess={() => successHandler('Docházka smazána')}
                  onError={() => errorHandler('Docházku se nepodařilo smazat')}
                />
              </Protect>
            )}
          </>
        </ShiftInfoDetailComponent>
      ) : null}

      {acceptedShiftHappend(application) && !application.attendance ? (
        <ShiftInfoDetailComponent label="Docházka nepotvrzena" />
      ) : null}

      {application.attendance?.personLiability ? (
        <ShiftInfoDetailComponent
          label="Manko"
          lastUpdate={application?.attendance?.personLiability.updated_at}
          createdAt={application?.attendance?.personLiability.created_at}
          detailComponent={
            <PersonLiabilityDetails attendance={application?.attendance} />
          }
        />
      ) : null}
      {application.excuses.length > 0 && (
        <AbsenceDetails excuses={application.excuses} />
      )}
    </div>
  );
};
export default ShiftInfoDetail;
